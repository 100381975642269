export default {
  "advanced-priority-target-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edistynyt valinta"])},
  "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilma"])},
  "apartment-profile-component-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda profiili"])},
  "apartment-profile-component-title-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda uusi profiili koko talolle"])},
  "apartment-profile-timers-component-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oheista + -painiketta painamalla voitte lisätä ajastuksia talonne lämpöprofiileille."])},
  "apartment-profile-timers-component-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajastukset"])},
  "btn-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta"])},
  "btn-close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulje"])},
  "btn-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi"])},
  "btn-copy-to-other-rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi muihin huoneisiin"])},
  "btn-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "btn-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
  "btn-reset-to-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palauta oletusasetuksiin"])},
  "btn-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna"])},
  "btn-save-and-copy-to-other-rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna ja kopioi"])},
  "change-password-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda salasana"])},
  "chart-adjusted-tempwish-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintamuokattu lämpötila asetus"])},
  "chart-allpowers-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokonaiskulutus"])},
  "chart-apartmentpowers-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulutus (Talo)"])},
  "chart-button-title-allpowers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokonais"])},
  "chart-button-title-apartmentpowers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talo"])},
  "chart-button-title-co2limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO<sub>2</sub> yläraja"])},
  "chart-button-title-energycost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköenergia"])},
  "chart-button-title-energytax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähkövero"])},
  "chart-button-title-heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämmitys"])},
  "chart-button-title-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisä"])},
  "chart-button-title-other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])},
  "chart-button-title-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulko"])},
  "chart-button-title-phases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaiheittain"])},
  "chart-button-title-powers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huone"])},
  "chart-button-title-production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuotto"])},
  "chart-button-title-sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myynti"])},
  "chart-button-title-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetus"])},
  "chart-button-title-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokonais"])},
  "chart-button-title-totalcost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokonaishinta"])},
  "chart-button-title-transfercost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähkönsiirto"])},
  "chart-button-title-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvonlisävero"])},
  "chart-button-title-vat-ele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALV (Energia)"])},
  "chart-button-title-vat-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALV (Siirto)"])},
  "chart-consum_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulutus"])},
  "chart-heatin_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämmitys"])},
  "chart-humidity-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosteus (Sisä)"])},
  "chart-indoortemps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpötila (Sisä - Ilma)"])},
  "chart-other_consum_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu kulutus"])},
  "chart-out-humidity-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosteus (Ulko)"])},
  "chart-out-temps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpötila (Ulko)"])},
  "chart-owregtemps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpötila (Sisä - Lattia)"])},
  "chart-powers-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulutus (Huone)"])},
  "chart-produc_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuotanto"])},
  "chart-sold_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myynti"])},
  "chart-temps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpötila (Sisä)"])},
  "chart-tempwish-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpötila asetus"])},
  "chartdata_consumption_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulutus L1"])},
  "chartdata_consumption_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulutus L2"])},
  "chartdata_consumption_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulutus L3"])},
  "chartdata_production_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuotto L1"])},
  "chartdata_production_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuotto L2"])},
  "chartdata_production_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuotto L3"])},
  "check-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkista sopimusasetukset"])},
  "choose-house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse talo"])},
  "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulutus"])},
  "consumption-calendar-7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edelliset 7 päivää"])},
  "consumption-calendar-30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edelliset 30 päivää"])},
  "consumption-calendar-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajalta"])},
  "copy-selected-to-other-rooms-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi valitut asetukset valittuihin huoneisiin"])},
  "copy-to-other-rooms-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi asetukset valittuihin huoneisiin"])},
  "copy-to-other-rooms-dialog-owreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvot tallentuvat kyseiseen OwReg-lattialämmitystermostaattiin sekä kopioituvat alla valittujen huoneiden kaikkiin OwReg-lattialämmitystermostaatteihin."])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivä"])},
  "dialog-settings-room-advanced-heating-selection-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit määritellä lämmittimien tyypit ja valita yksittäisiä lämmittimiä huoneen pääasiallisiksi lämmittimiksi."])},
  "dialog-settings-room-advanced-heating-selection-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edistynyt hallinta"])},
  "dialog-settings-room-owreg-settings-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit asettaa alla oletusarvot OwReg-lattialämmitystermostaatin toiminnalle eri tilanteissa. Painamalla kysymysmerkki-kuvaketta saat tarkemman kuvauksen kustakin säädöstä."])},
  "dialog-settings-room-owreg-settings-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OwReg-lattialämmitystermostaatin asetukset"])},
  "electricity-price-tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähkön hinta"])},
  "energycost-consumption-so-far": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokonaishinta tähän mennessä"])},
  "energycost-cumulative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokonaishinta"])},
  "energycost-estimated-usage-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ajanjaksolla arvioitu <b>lämmitykseen kulutetun</b> energian määrä ", _interpolate(_named("usage")), ".<br>Kokonaishinta ", _interpolate(_named("cost")), ", keskihinta ", _interpolate(_named("average_cost")), "."])},
  "energycost-hourly-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntihinta"])},
  "energycost-usage-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ajanjaksolla OptiWatilla mitattu <b>ostetun</b> energian määrä ", _interpolate(_named("usage")), ".<br>Kokonaishinta ", _interpolate(_named("cost")), ", keskihinta ", _interpolate(_named("average_cost")), "."])},
  "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lattia"])},
  "for_sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiin"])},
  "forecast-not-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sääennustetta ei ole juuri nyt saatavilla."])},
  "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unohtuiko salasana?"])},
  "forgotten-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna sähköpostisi niin lähetämme sinulle linkin, jonka kautta voit vaihtaa salasanasi.<br/><br/>Jos olet unohtanut käyttäjätunnuksesi, ota yhteyttä asiakaspalveluumme, puh. 029&nbsp;1701&nbsp;007"])},
  "forgotten-password-field-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
  "forsale-dialog-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lisäpalvelu tilattavissa. Ota yhteys asiakaspalveluumme ", _interpolate(_named("salesemail")), " / 029 1701 007"])},
  "guidedsetup-alldone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2>Onnittelut</h2>Kaikki asetukset on säädetty."])},
  "heatpump-mode-auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaatti"])},
  "heatpump-mode-cool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viilennys"])},
  "heatpump-mode-heat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämmitys"])},
  "heatpump-mode-heatoff-confirmation-option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koko talosta?"])},
  "heatpump-mode-heatoff-confirmation-option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vain tästä huoneesta?"])},
  "heatpump-mode-heatoff-confirmation-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitsemasi tila vaatii lämmityksen poiskytkennän.<br/>Haluatko kytkeä lämmityksen pois"])},
  "heatpump-mode-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pois päältä"])},
  "heatsource-curve-in-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menovesi"])},
  "heatsource-curve-out-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulkolämpötila"])},
  "heatsource-curve-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säätökäyrä"])},
  "heatsource-curve-xaxis-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulkolämpötila / °C"])},
  "heatsource-curve-yaxis-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menoveden lämpötila / °C"])},
  "ignitiontimes-temperature-dialog-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämmitysajat minuuteissa eri lämpötiloissa"])},
  "in-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteensä"])},
  "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkasta sähköpostiosoite"])},
  "invalid-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkki on vanhentunut"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan"])},
  "localized-profile-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viilennys"])},
  "localized-profile-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämmitys pois"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu"])},
  "moments-energycost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkasteltavan hetken energian hinta"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausi"])},
  "move-only-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutokset tunneittain"])},
  "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi salasana"])},
  "new-password-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varmista uusi salasana"])},
  "new-version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi versio saatavilla."])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yö"])},
  "no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitään ei löytynyt"])},
  "none-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei valintaa"])},
  "note-undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrittelemätön"])},
  "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteysvirhe"])},
  "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanha salasana"])},
  "old-password-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanha salasana on väärin"])},
  "oldpassword-null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötä vanha salasana"])},
  "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteystilassa"])},
  "ow-alert-code-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneyksikön paristo vaihdettava"])},
  "ow-alert-code-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ILP ohjaimen paristo vaihdettava"])},
  "ow-alert-code-1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneen lämpötila alle raja-arvojen"])},
  "ow-alert-code-1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneen lämpötila yli raja-arvojen"])},
  "ow-alert-code-1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneen kosteus alle raja-arvojen"])},
  "ow-alert-code-1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneen kosteus yli raja-arvojen"])},
  "ow-alert-code-2001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittausdata vanhentunut"])},
  "ow-alert-code-2002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huono yhteys sensoriin"])},
  "ow-alert-code-2003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huono yhteys lämmittimeen"])},
  "ow-alert-code-2004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei yhteyttä lämmittimeen"])},
  "ow-alert-code-2005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vesivuoto havaittu"])},
  "ow-alert-code-2006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OwReg-lattialämmitystermostaatin lämpöanturi vikatilassa"])},
  "ow-alert-code-2602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2-pitoisuus yli raja-arvon"])},
  "ow-alert-code-5000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei yhteyttä keskusyksikköön"])},
  "ow-alert-code-5001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väärä sähköpostiosoite"])},
  "ow-alert-code-5002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähkösopimuksen tiedot puuttuvat"])},
  "ow-alert-code-5003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liikettä havaittu"])},
  "ow-confirm-profile-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti vaihtaa uuden asetuksen kaikkiin huoneisiin?"])},
  "ow-error-access-forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttöoikeus kohteeseen puuttuu"])},
  "ow-error-token-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istunto on vanhentunut. Ole hyvä ja kirjaudu uudestaan."])},
  "ow-guided-setup-moistalert-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä kosteuden hälytysrajat kaikkiin huoneisiin."])},
  "ow-guided-setup-tempalert-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä lämpötilojen hälytysrajat kaikkiin huoneisiin."])},
  "ow-help-guided-setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttöopastus"])},
  "ow-help-terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttöehdot"])},
  "ow-helper-setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä"])},
  "ow-helper-skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohita"])},
  "ow-helper-text-algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Ennakoiva lämmitys</h4><p>Ennakoivalla lämmityksellä parannat asuinmukavuutta.</p>"])},
  "ow-helper-text-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Sähkösopimus</h4><p>Tarkista, että sähkösopimuksesi on määritelty vastaamaan voimassaolevaa sopimusta.</p>"])},
  "ow-helper-text-moistalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Kosteuden hälytysrajat</h4><p>Aseta huoneiden kosteudelle hälytysrajat saadaksesi ilmoituksen normaalista poikkeavista kosteuslukemista.</p>"])},
  "ow-helper-text-tempalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Lämpötilojen hälytysrajat</h4><p>Aseta huoneiden lämpötilalle hälytysrajat saadaksesi ilmoituksen normaalista poikkeavista lämpötiloista.</p>"])},
  "ow-http-error-400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virheellinen pyyntö"])},
  "ow-no-solarpanels": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>OptiWatti energia-analyysi on osa OptiWatti Solar -moduulia.<br>\nTiedustelut ja tilaukset: ", _interpolate(_named("salesemail")), " / 029 1701 007</p>"])},
  "ow-timer-dialog-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajastus"])},
  "owreg-err-dutycycle-over-suggested": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Arvo ylittää suositellun raja-arvon (", _interpolate(_named("limit")), " %)!"])},
  "owreg-tmax-offline-over-suggested": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Arvo ylittää suositellun raja-arvon (", _interpolate(_named("limit")), " °C)!"])},
  "owreg-tmax-over-suggested": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Arvo ylittää suositellun raja-arvon (", _interpolate(_named("limit")), " °C)!"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])},
  "password-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasanasi on vaihdettu"])},
  "password-link-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkki jolla voit vaihtaa salasanasi on lähetetty sähköpostiisi"])},
  "password-not-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana ei täytä ehtoja"])},
  "password-test-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasanan tulee olla vähintään 10 merkkiä pitkä ja koostua isoista ja pienistä kirjaimista ja numeroista."])},
  "password-too-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana on liian lyhyt"])},
  "passwords-dont-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasanat eivät täsmää"])},
  "possible-bad-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähkösopimuksen hinnoittelemattomista kohdista johtuen luvut eivät välttämättä täsmää."])},
  "possible-low-temperature-alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["HUOM! Lämpötilat saattavat asetetuilla arvoilla mennä alle suositellun ", _interpolate(_named("limit")), " lämpötilan!"])},
  "possible-low-temperature-alert-rooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["HUOM! Lämpötilat saattavat asetetuilla arvoilla mennä joissain huoneissa alle suositellun ", _interpolate(_named("limit")), " lämpötilan!"])},
  "possible-low-temperature-dont-bother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä näytä tätä varoitusta enää"])},
  "possible-low-temperature-want-to-continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti jatkaa valituilla arvoilla?"])},
  "primary-owreg-toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aseta ensisijaiseksi"])},
  "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuotanto"])},
  "production_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Omasta tuotannosta käytetty ", _interpolate(_named("percents")), " % / säästetty ", _interpolate(_named("euros")), " €"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetus"])},
  "realtime-at-the-moment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tällä hetkellä"])},
  "realtime-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaaliaikadata"])},
  "reset_password_mail_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkasta käyttäjätunnus tai sähköpostiosoite"])},
  "reset_password_mail_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkki jolla voit vaihtaa salasanasi on lähetetty sähköpostiisi"])},
  "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huone"])},
  "room-view-temperature-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpöasetukset"])},
  "save-to-other-rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna muihin huoneisiin"])},
  "save-to-other-rooms-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna myös valittuihin huoneisiin"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsi"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä"])},
  "set_ilp_fanspeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuuletusnopeus"])},
  "set_ilp_horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaakasuunta"])},
  "set_ilp_mode_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaattitila"])},
  "set_ilp_mode_cool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viilennystila"])},
  "set_ilp_mode_heat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämmitystila"])},
  "set_ilp_vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pystysuunta"])},
  "settings-apartment-info-emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talokohtaiset hälytykset"])},
  "settings-apartment-info-emails-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(erota sähköpostit eri riveille)"])},
  "settings-apartment-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talon nimi"])},
  "settings-apartment-streetaddess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katuosoite"])},
  "settings-apartment-wifi-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi salasana"])},
  "settings-apartment-wifi-ssid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi SSID"])},
  "settings-change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda salasana"])},
  "settings-contract-c-per-kwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c/kWh"])},
  "settings-contract-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivä"])},
  "settings-contract-energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia"])},
  "settings-contract-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yö"])},
  "settings-contract-pricing-generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleishinta"])},
  "settings-contract-pricing-market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markkinahinta"])},
  "settings-contract-pricing-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yöhinta"])},
  "settings-contract-pricing-season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kausihinta"])},
  "settings-contract-sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myynti"])},
  "settings-contract-season-another-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu aika"])},
  "settings-contract-season-winter-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talvipäivä"])},
  "settings-contract-spot-margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välityspalkkio"])},
  "settings-contract-spot-provision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välityspalkkio"])},
  "settings-contract-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirto"])},
  "settings-contract-use-spot-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä spot ohjausta"])},
  "settings-electrical-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähkösopimus"])},
  "settings-heater-owreg-fault-pulse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulssisuhde anturivauriossa"])},
  "settings-heater-owreg-local-guide-temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vikatilanne lämpötila"])},
  "settings-heater-owreg-protect-temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lattian maksimi lämpötila"])},
  "settings-heatpump-calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpötilakorjaus"])},
  "settings-heatpump-lowest-operating-temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alin käyttölämpötila"])},
  "settings-heatpump-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
  "settings-heatpump-other-heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu lämmitys"])},
  "settings-heatsources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpölähteet"])},
  "settings-heatsources-list-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpölähteen nimi"])},
  "settings-profiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiilit"])},
  "settings-profiles-name-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normaali profiilin nimi"])},
  "settings-profiles-name-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säästö profiilin nimi"])},
  "settings-room-autoheat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennakoiva lämmitys"])},
  "settings-room-co2-alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO<sub>2</sub> yläraja"])},
  "settings-room-heater-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämmitintyyppi"])},
  "settings-room-heating-algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämmitystapa"])},
  "settings-room-heatpump-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmalämpöpumppuasetukset"])},
  "settings-room-humidity-alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosteuden hälytysarvot"])},
  "settings-room-hysteresis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hysteresis"])},
  "settings-room-max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max"])},
  "settings-room-min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
  "settings-room-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneen nimi"])},
  "settings-room-power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämmitysteho"])},
  "settings-room-primary-heat-source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pääasiallinen lämmitysmuoto"])},
  "settings-room-temperature-alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpötilan hälytysarvot"])},
  "settings-solar-optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar optimointi"])},
  "settings-solar-optimization-instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse prioriteettijärjestys ylituotannon osalta."])},
  "settings-spot-control-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot-ohjauksen asetukset"])},
  "settings-stepsize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säätötarkkuus"])},
  "settings-stepsize-lower-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alaraja"])},
  "settings-stepsize-precision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkkuus"])},
  "settings-switches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kytkimet"])},
  "settings-switches-list-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kytkimen nimi"])},
  "settings-user-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katuosoite"])},
  "settings-user-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaupunki"])},
  "settings-user-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
  "settings-user-firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etunimi"])},
  "settings-user-lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukunimi"])},
  "settings-user-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhelin"])},
  "settings-user-zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postinumero"])},
  "short-day-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su"])},
  "short-day-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma"])},
  "short-day-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti"])},
  "short-day-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ke"])},
  "short-day-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "short-day-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pe"])},
  "short-day-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La"])},
  "short-day-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su"])},
  "show-electricity-prices-on-graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä sähkön kokonaishinta kulutusgraafeilla"])},
  "spot-control-intro-cheaper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), " halvempi, niin tavoitelämpötilaa nostetaan ", _interpolate(_named("degree"))])},
  "spot-control-intro-expensive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), " kalliimpi, niin tavoitelämpötilaa lasketaan ", _interpolate(_named("degree"))])},
  "spot-control-lower-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot ohjatun lämpötilan alaraja"])},
  "spot-control-price-brackets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintaluokat"])},
  "spot-control-price-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähkön tuntihinta on keskihintaa:"])},
  "spot-control-profile1-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassinen OptiWatti spot ohjaus painottaa mukavuuslämpöä ja lämpötilamuutokset ovat maltillisia. \n<br><br>\nOptiWatti laskee väliaikaisesti huoneen lämpötilatoivetta \nvuorokauden keskihintaa kalliimpien tuntien ajaksi ja vastaavasti nostaa halvempien tuntien aikana."])},
  "spot-control-profile2-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säästö OptiWatti spot ohjaus painottaa säästöjä \nenemmän mukavuuden kustannuksella. \n<br><br>\nOptiWatti laskee väliaikaisesti huoneen lämpötilatoivetta \nvuorokauden keskihintaa kalliimpien tuntien ajaksi ja vastaavasti nostaa halvempien tuntien aikana."])},
  "spot-control-profile3-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintapainotettu OptiWatti spot ohjaus painottaa vain säästöjä ja lämpötilamuutokset ovat suuria.\n<br><br>\nOptiWatti laskee väliaikaisesti huoneen lämpötilatoivetta \nvuorokauden keskihintaa kalliimpien tuntien ajaksi ja vastaavasti nostaa halvempien tuntien aikana."])},
  "spot-control-profile4-help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintaluokka spot-ohjaus:\n\n• Valittavana on 3 hintaluokkaa sähkön kokonaishinnalle (siirto+energia+verot)\n\n• Kullekin hintaluokalle valitaan “Pudota vähintään” -kenttään astemäärä sekä valitaan säädön jyrkkyys kussakin luokassa: Ei mitään, Loiva, Maltillinen, Jyrkkä\n\n• Valittu jyrkkyys sisältää lämpötilatoiveen väliaikaisen säädön vuorokauden keskihintaan suhteutettuna"])},
  "spot-control-profile4-help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OptiWatti säätää lämpötilatoivetta väliaikaisesti seuraavasti:\n\n1. Hintaluokka valitaan kunkin tunnin sähkön kokonaishinnan mukaan. \n\n2. Lämpötilatoivetta säädetään hintaluokan “Pudota vähintään”-kentän verran\n\n3. Hintaluokan lisäohjaus kenttään valittu ohjaus laskee lämpötilatoivetta vuorokauden keskihintaa merkittävästi kalliimpien tuntien ajaksi. Edullisimmassa hintaluokassa keskihintaa merkittävästi halvempien tuntien ajaksi lämpötilatoivetta myös nostetaan. "])},
  "spot-control-range-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei spot ohjausta"])},
  "spot-control-range-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loiva"])},
  "spot-control-range-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maltillinen"])},
  "spot-control-range-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jyrkkä"])},
  "spot-control-tab1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassinen"])},
  "spot-control-tab2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säästö"])},
  "spot-control-tab3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Äärimmäinen"])},
  "spot-control-tab4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiantuntija"])},
  "spot-control-tab4-energy-price-brackets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähkön hintaluokat"])},
  "spot-control-tab4-temperature-variations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpötilan säädön vaihteluväli hintaluokissa"])},
  "spot-control-temperature-variations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpötilan vaihteluvälit"])},
  "spot-control-upper-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot ohjatun lämpötilan yläraja"])},
  "spot-drop-atleast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pudota vähintään"])},
  "spot-no-spot-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei spot ohjausta"])},
  "spot-price-and-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja yli"])},
  "spot-price-below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "spot-price-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vähintään"])},
  "subtitle-last-connection-since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viimeisin yhteys"])},
  "switch-alwaysoff-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUOM: Kytkin on pois päältä"])},
  "switch-alwayson-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUOM: Kytkin on jatkuvasti PÄÄLLÄ"])},
  "switch-common-name-carheater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auton lämmitys"])},
  "switch-common-name-generalswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kytkin"])},
  "switch-common-name-heatsourceswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpölähde"])},
  "switch-common-name-movementsensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liiketunnistin"])},
  "switch-common-name-waterheater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vesivaraaja"])},
  "switch-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohjaus"])},
  "switch-controltype-name-alwaysoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pois"])},
  "switch-controltype-name-alwayson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatkuva"])},
  "switch-controltype-name-cheaphours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halvat tunnit"])},
  "switch-controltype-name-expensivehours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalliit tunnit"])},
  "switch-controltype-name-forceon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päällä"])},
  "switch-controltype-name-ignitiontimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähtöajat"])},
  "switch-controltype-name-solarhours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FI: Solar Hours"])},
  "switch-controltype-name-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnit"])},
  "switch-expensivehours-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kuinka monta päivän kalleinta tuntia vältetään"])},
  "switch-for-sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilattavissa"])},
  "switch-forceon-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUOM: Kytkin on PÄÄLLÄ seuraavaan vuorokauden vaihteeseen"])},
  "switch-on-immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kytke päälle heti"])},
  "switch-on-immediately-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti kytkeä tämän kytkimen päälle seuraavaan vuorokauden vaihteeseen saakka?"])},
  "tempwish-follows-owreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpötoive seuraa lattian lämpöä (OwReg-lattialämmitystermostaatti)"])},
  "tempwish-follows-owsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpötoive seuraa huoneanturia"])},
  "terms-agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksy"])},
  "terms-reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylkää"])},
  "text-electricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähkö"])},
  "text-water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vesi"])},
  "tooltip-settings-heater-owreg-fault-pulse": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos lattian lämpötilaa ei saada anturilta, ajetaan lattialämmitystä pulssisuhteella (0-50%). Suositeltava maksimiarvo on ", _interpolate(_named("limit")), " %. Voit ylittää suosituksen, mutta omalla vastuulla."])},
  "tooltip-settings-heater-owreg-local-guide-temp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mihin lämpötilaan (0-30 °C) lattia säädetään tilanteessa jossa keskusyksikköön ei saada yhteyttä. Suositeltu maksimiarvo on ", _interpolate(_named("limit")), " °C. Voit ylittää suosituksen, mutta omalla vastuulla."])},
  "tooltip-settings-heater-owreg-protect-temp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lattian suurin sallittu lämpötila välillä 0-30 °C. Suositeltu maksimiarvo on ", _interpolate(_named("limit")), " °C. Voit ylittää suosituksen, mutta omalla vastuulla."])},
  "undefined-plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrittelemätön"])},
  "undefined-singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrittelemätön"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjätunnus"])},
  "vat0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALV 0 %"])},
  "vat24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALV 24 %"])},
  "view-apartments-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse talo"])},
  "view-consumption-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulutus"])},
  "view-energycost-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energian hinta"])},
  "view-help-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohjeet"])},
  "view-production-and-consumption-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuotanto / Kulutus"])},
  "view-profiles-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lämpöasetukset"])},
  "view-settings-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])},
  "view-switches-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kytkimet"])},
  "warning-dont-bother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä näytä uudestaan"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viikko"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuosi"])}
}
<template>
  <div class="login">
    <div class="login-logo company-logo"></div>

    <form @submit.prevent="login" class="login-form">
        <div class="p-field p-col">
          <InputText type="text" v-model="username" autocomplete="username" v-bind:placeholder="$t('username')" autocapitalize="none" id="username"/><small class="p-error" id="username-error"></small>
        </div>
        <div class="p-field p-col">
          <InputText type="password" v-model="password" autocomplete="current-password" v-bind:placeholder="$t('password')" id="password"/><small class="p-error" id="password-error"></small>
        </div>
        <div class="p-field p-col">
          <Button type="submit">{{ $t('login')}}</Button>
        </div>
        <div class="p-field p-col message">
          {{ logoutMessage }}
        </div>
    </form>
    <a :href="geturl">{{ $t('forgot-password')}}</a>

    <LocaleChanger :forceLanguage="forcelocale" />
  </div>
</template>

<script>
/*

  views/Login.vue

  Login view

  Shows login form and LocaleChanger component for language switching

  Also shows possible logoutMessages ie. Expired token errors

*/

import axios from 'axios';
import LocaleChanger from '@/components/LocaleChanger.vue';

export default {
  name: 'Login',
  emits: ["loggedin"],
  data() {
      return {
          forcelocale: null,
          username: null,
          password: null,
          localchecked: false, // check localstorage for token
      }
  },
  computed: {
    logoutMessage: function() {
      return this.$currentUser.reasonForLogout; // Show the global reactive state (main.js) reasonForLogout message
    },
    geturl: function() {
      return '/requestpasswordreset';

      /*
        if(typeof(process.env.VUE_APP_OW_PASSWORD_CHANGE_URL) != 'undefined') {
            var kieli = '?lang=' + this.$i18n.locale;
            //if(this.$i18n.locale == 'fi') kieli = '';
            // Muutos xxx

            return process.env.VUE_APP_OW_PASSWORD_CHANGE_URL + kieli;
        } else {
            return 'https://old.optiwatti.fi/salasana/';
        }
      */
    }
  },
  components: {
    LocaleChanger,
  },
  mounted() {
    document.body.classList.add('login');
  },
  methods: {
    login() {

      var errortexts = document.getElementsByClassName('p-error');
      for (let element of errortexts) {
        element.innerHTML = '';
      }

      const logindata = { username: this.username, password: this.password };
      
      delete axios.defaults.headers.common["Authorization"]; // Remove authorization header for login attempt! // UN-341, UN-343, UN-344

      axios.post(process.env.VUE_APP_OW_API_URL + "api-token-auth/", logindata)
        .then(response => {
          axios.defaults.headers.common['Authorization'] = 'Token ' + response.data.token; // Set default header for axios requests
          this.$currentUser.token = 'Token ' + response.data.token; // Set token in the global reactive state (main.js)
          this.$currentUser.username = this.username; // Set username in the global reactive state (main.js)
          localStorage.setItem('token', this.$currentUser.token); // Set token also in the localstorage

          // Set username in localstorage as well

          this.$router.push('/apartments');
          this.$emit('loggedin', 'success');
          this.$toast.removeAllGroups(); // Clears all toast errors

        })
        .catch((error) => {
                if(Object.keys(error.data.fields).length) {
                    for(const [key, value] of Object.entries(error.data.fields)) {
                        document.getElementById(key).classList.add('p-invalid');
                        document.getElementById(key + '-error').innerHTML = value;
                    }
                }

        }) // This needs to be here even with global error handling
      },
    localCheck() {
      // Check is localstorage has token
      // var theLocalToken

      // Test token to api if not expired 
      // disable error messages to user
      // maybe create different axios so doesn't use global error handling!

      // if Token still valid do 
      /*
          axios.defaults.headers.common['Authorization'] = theLocalToken; // Set default header for axios requests
          this.$currentUser.token = theLocalToken; // Set token in the global reactive state (main.js)
          this.$currentUser.username = this.username; // Set username in the global reactive state (main.js)
          localStorage.setItem('token', this.$currentUser.token); // Set token also in the localstorage

          this.$router.push('/apartments');
          this.$emit('loggedin', 'success');
          this.$toast.removeAllGroups(); // Clears all toast errors

      */
    }
  },
}
</script>

<style lang="scss" scoped>
  .login {
    width:100%;
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    text-align:center;
    padding-right: env(safe-area-inset-right);
    
    .login-logo {
      display:inline-block;
      width:25%;
      aspect-ratio: 4 / 1; // polyfilled

      background-color:$login-logo;
      display:inline-block;
      mask-position:center center;
      mask-repeat: no-repeat;
      mask-size:100%;

      
      &::before {
        float: left;
        padding-top: 25%;
        content: '';
      }

      &::after {
        display: block;
        content: '';
        clear: both;
      }
    }
    .login-form {
      width:25%;
      margin:0 auto;

      .p-col {
        padding:0;
      }

      .p-field {
        margin-bottom:0.25rem;
      }

      .p-button {
        width:100%;
        justify-content:center;
        font-size:1.125rem;
        margin-top:1rem;
        margin-bottom:1rem;
      }

      input {
        width:100%;
        text-align:center;
        padding:0.75rem;
        font-size:1.125rem;
      }

      .message {
        margin-bottom:1rem;
        color:$ow-alert;

      }

      .p-invalid {
        border-color: $ow-alert;
      }

      .p-error {
        color: $ow-alert;
      }
    }

    a {
      color: $text;
      display:inline-block;
      margin-bottom:1rem;
    }


  }

  .locale-changer {
    width:100%;
    text-align:center;
    margin-top:2rem;
  }


</style>
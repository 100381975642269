/*

    index.js

    Contains basic definitions of Vuex modules
    Individual modules are in the modules subfolder

*/

import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import moduleApartments from './modules/moduleApartments.js'
import moduleRooms from './modules/moduleRooms.js'
import moduleTemperatureProfiles from './modules/moduleTemperatureProfiles.js'
import moduleSwitches from './modules/moduleSwitches.js'
import moduleGeneral from './modules/moduleGeneral.js'
import moduleAPISchema from './modules/moduleAPISchema.js';
import moduleUISettings from './modules/moduleUISettings.js';
import moduleMeasurementdata from './modules/moduleMeasurementdata';
import moduleAlerts from './modules/moduleAlerts';
import moduleRootAPI from './modules/moduleRootAPI';
import moduleAlgorithm from './modules/moduleAlgorithm';

export const store = createStore({
    modules: {
        general: moduleGeneral,
        apartments: moduleApartments,
        rooms: moduleRooms,
        switches: moduleSwitches,
        apischema: moduleAPISchema,
        temperatureprofiles: moduleTemperatureProfiles,
        uisettings: moduleUISettings,
        measurementdata: moduleMeasurementdata,
        alerts: moduleAlerts,
        rootapi: moduleRootAPI,
        algorithm: moduleAlgorithm
    },
    plugins: [createPersistedState({
        paths: ['general', 'apartments', 'rooms', 'apischema', 'temperatureprofiles', 'switches', 'uisettings', 'measurementdata', 'alerts', 'rootapi', 'algorithm']
    })]
})
export default {
  "advanced-priority-target-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerat urval"])},
  "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luft"])},
  "apartment-profile-component-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt profil"])},
  "apartment-profile-component-title-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt profil för hela huset"])},
  "apartment-profile-timers-component-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genom att trycka på + knappen, kan du lägga till timer för husets temperaturprofiler."])},
  "apartment-profile-timers-component-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer"])},
  "btn-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
  "btn-close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stäng"])},
  "btn-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera"])},
  "btn-copy-to-other-rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera till andra rum"])},
  "btn-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "btn-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
  "btn-reset-to-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ till standard"])},
  "btn-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara"])},
  "btn-save-and-copy-to-other-rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara och kopiera"])},
  "change-password-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra lösenord"])},
  "chart-adjusted-tempwish-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisjusterad temperaturinställning"])},
  "chart-allpowers-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total förbrukning"])},
  "chart-apartmentpowers-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förbrukning (Hus)"])},
  "chart-button-title-allpowers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
  "chart-button-title-apartmentpowers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hus"])},
  "chart-button-title-co2limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO<sub>2</sub>-gräns"])},
  "chart-button-title-energycost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektrisk energi"])},
  "chart-button-title-energytax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elskatt"])},
  "chart-button-title-heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppvärmning"])},
  "chart-button-title-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
  "chart-button-title-other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annat"])},
  "chart-button-title-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ute"])},
  "chart-button-title-phases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faser"])},
  "chart-button-title-powers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rum"])},
  "chart-button-title-production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion"])},
  "chart-button-title-sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försäljning"])},
  "chart-button-title-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställning"])},
  "chart-button-title-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
  "chart-button-title-totalcost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalbelopp"])},
  "chart-button-title-transfercost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Överföring"])},
  "chart-button-title-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOMS"])},
  "chart-button-title-vat-ele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOMS (Energi)"])},
  "chart-button-title-vat-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOMS (Överföring)"])},
  "chart-consum_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förbrukning"])},
  "chart-heatin_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppvärmning"])},
  "chart-humidity-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftfuktighet (Inne)"])},
  "chart-indoortemps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatur (Inne - Luft)"])},
  "chart-other_consum_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Övrig konsumtion"])},
  "chart-out-humidity-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftfuktighet (Ute)"])},
  "chart-out-temps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatur (Ute)"])},
  "chart-owregtemps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatur (Inne - Golv)"])},
  "chart-powers-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förbrukning (Rum)"])},
  "chart-produc_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion"])},
  "chart-sold_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Såld"])},
  "chart-temps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatur (Inne)"])},
  "chart-tempwish-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturinställning"])},
  "chartdata_consumption_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förbrukning L1"])},
  "chartdata_consumption_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förbrukning L2"])},
  "chartdata_consumption_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förbrukning L3"])},
  "chartdata_production_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion L1"])},
  "chartdata_production_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion L2"])},
  "chartdata_production_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion L3"])},
  "check-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollera kontraktsinställningarna"])},
  "choose-house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj hus"])},
  "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förbrukning"])},
  "consumption-calendar-7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 dagar"])},
  "consumption-calendar-30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 dagar"])},
  "consumption-calendar-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidsperiod"])},
  "copy-selected-to-other-rooms-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera valda inställningar till valda rum"])},
  "copy-to-other-rooms-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera inställningar till valda rum"])},
  "copy-to-other-rooms-dialog-owreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värdena lagras i den aktuella OwReg Termostaten och kopieras till alla OwReg Termostater i de rum som väljs nedan."])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dag"])},
  "dialog-settings-room-advanced-heating-selection-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan definiera typerna av värmare och välja enskilda värmare som huvudvärmare i rummet."])},
  "dialog-settings-room-advanced-heating-selection-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerad kontroll"])},
  "dialog-settings-room-owreg-settings-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan ställa in standardvärdena nedan för driften av OwReg Termostaten i olika situationer. Tryck på frågetecknet för att få en mer detaljerad beskrivning av varje inställning."])},
  "dialog-settings-room-owreg-settings-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OwReg Termostat inställningar"])},
  "electricity-price-tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elpris"])},
  "energycost-consumption-so-far": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalbelopp under perioden hittills"])},
  "energycost-cumulative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalbelopp"])},
  "energycost-estimated-usage-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uppskattad mängd energi som <b>förbrukats för uppvärmning</b> under perioden ", _interpolate(_named("usage")), ".<br>Totalpris ", _interpolate(_named("cost")), ", genomsnittspris ", _interpolate(_named("average_cost")), "."])},
  "energycost-hourly-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timpris"])},
  "energycost-usage-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mängden <b>köpt</b> energi mätt av OptiWatti under perioden ", _interpolate(_named("usage")), ".<br>Totalpris ", _interpolate(_named("cost")), ", genomsnittspris ", _interpolate(_named("average_cost")), "."])},
  "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golv"])},
  "for_sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till salu"])},
  "forecast-not-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väderprognosen är inte tillgängling just nu."])},
  "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glömt ditt lösenord?"])},
  "forgotten-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv in ditt email, så skickar vi en länk för att återställa ditt lösenord.<br/><br/>Om du har glömt ditt lösenord, kontakta vår kundtjänst"])},
  "forgotten-password-field-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])},
  "forsale-dialog-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denna tjänst finns som extraval. Vänligen kontakta kundtjänst ", _interpolate(_named("salesemail")), " / +358 (0)29 1701 007"])},
  "guidedsetup-alldone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2>Grattis</h2>Alla inställningar är inställda."])},
  "heatpump-mode-auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisk"])},
  "heatpump-mode-cool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kylning"])},
  "heatpump-mode-heat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppvärmning"])},
  "heatpump-mode-heatoff-confirmation-option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hela huset?"])},
  "heatpump-mode-heatoff-confirmation-option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bara av det här rummet?"])},
  "heatpump-mode-heatoff-confirmation-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det valda läget kräver uppvärmningen slås av.<br/>Vill du slå av uppvärmningen"])},
  "heatpump-mode-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Av"])},
  "heatsource-curve-in-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatten"])},
  "heatsource-curve-out-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ute"])},
  "heatsource-curve-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justeringskurva"])},
  "heatsource-curve-xaxis-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utetemperatur / °C"])},
  "heatsource-curve-yaxis-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vattentemperatur / °C"])},
  "ignitiontimes-temperature-dialog-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppvärmningsgränser i olika temperaturer"])},
  "in-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
  "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollera email"])},
  "invalid-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länken har upphört att gälla"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laddar"])},
  "localized-profile-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kylning"])},
  "localized-profile-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppvärmning av"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
  "moments-energycost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priset på energi för närvarande granskas"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månad"])},
  "move-only-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändringar per timme"])},
  "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt lösenord"])},
  "new-password-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta nytt lösenord"])},
  "new-version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny version tillgänglig."])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natt"])},
  "no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga resultat funna"])},
  "none-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen vald"])},
  "note-undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odefinierad"])},
  "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem med anslutningen"])},
  "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gammalt lösenord"])},
  "old-password-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gamla lösenordet är fel"])},
  "oldpassword-null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange det gamla lösenordet"])},
  "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansluten"])},
  "ow-alert-code-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt rumsenhetens batteri"])},
  "ow-alert-code-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt luftvärmepumpens kontrollenhets batteri"])},
  "ow-alert-code-1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumstemperatur under gränsvärdet"])},
  "ow-alert-code-1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumstemperatur över gränsvärdet"])},
  "ow-alert-code-1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumsfuktigheten under gränsvärdet"])},
  "ow-alert-code-1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumsfuktigheten över gränsvärdet"])},
  "ow-alert-code-2001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mätdatan är inaktuell"])},
  "ow-alert-code-2002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dålig anslutning till sensor"])},
  "ow-alert-code-2003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dålig anslutning till värmare"])},
  "ow-alert-code-2004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen anslutning till värmare"])},
  "ow-alert-code-2005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vattenläckage upptäckt"])},
  "ow-alert-code-2602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2-koncentration över gränsvärdet"])},
  "ow-alert-code-5000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen kontakt till centralenhet"])},
  "ow-alert-code-5001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltig e-postadress"])},
  "ow-alert-code-5002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information om elkontraktet saknas"])},
  "ow-alert-code-5003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rörelse upptäckt"])},
  "ow-confirm-profile-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill ändra denna inställning för alla rum?"])},
  "ow-error-access-forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinationsåtkomst saknas"])},
  "ow-error-token-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din session har gått ut. Vänligen logga in igen."])},
  "ow-guided-setup-moistalert-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sätt larmgräns för luftfuktighet i alla rum."])},
  "ow-guided-setup-tempalert-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sätt larmgräns för temperatur i alla rum."])},
  "ow-help-guided-setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruktioner"])},
  "ow-help-terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarvillkor"])},
  "ow-helper-setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurera"])},
  "ow-helper-skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoppa över"])},
  "ow-helper-text-algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Förutseende uppvärmning</h4><p>Förutseende uppvärmning förbättrar boendekomforten.</p>"])},
  "ow-helper-text-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Elavtal</h4><p>Kontrollera att ditt elavtal är inställt enligt gällande avtal.</p>"])},
  "ow-helper-text-moistalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Luftfuktighetens larmnivå</h4><p>Ange rummens larmgräns för luftfuktighet för att bli meddelad vid onormala fuktnivåer.</p>"])},
  "ow-helper-text-tempalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Temperaturens larmnivå</h4><p>Ange rummens larmgräns för temperatur för att bli meddelad vid onormala temperaturer.</p>"])},
  "ow-http-error-400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dålig förfrågan"])},
  "ow-no-solarpanels": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>OptiWatti energianalys är en del av OptiWatti Solar-modulen.<br>\nFörfrågningar och beställningar: ", _interpolate(_named("salesemail")), " / +358 (0)29 1701 007</p>"])},
  "ow-timer-dialog-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer"])},
  "owreg-err-dutycycle-over-suggested": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Värdet överskrider den rekommenderade gränsen (", _interpolate(_named("limit")), " %)!"])},
  "owreg-tmax-offline-over-suggested": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Värdet överskrider den rekommenderade gränsen (", _interpolate(_named("limit")), " °C)!"])},
  "owreg-tmax-over-suggested": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Värdet överskrider den rekommenderade gränsen (", _interpolate(_named("limit")), " °C)!"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
  "password-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordet har ändrats"])},
  "password-link-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En länk där du kan återställa ditt lösenord har skickats till din email"])},
  "password-not-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordet är inte giltigt"])},
  "password-test-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordet måste vara minst 10 tecken långt och bestå av stora och små bokstäver och siffror."])},
  "password-too-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordet är för kort"])},
  "passwords-dont-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenorden stämmer inte överens"])},
  "possible-bad-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På grund av elavtalets nollprisfält kan det hända att siffrorna inte stämmer överens."])},
  "possible-low-temperature-alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["OBS! Temperaturerna kan gå under den rekommenderade ", _interpolate(_named("limit")), " temperaturen med de inställda värdena!"])},
  "possible-low-temperature-alert-rooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["OBS! I vissa rum kan temperaturerna gå under den rekommenderade ", _interpolate(_named("limit")), " temperaturen med de inställda värdena!"])},
  "possible-low-temperature-dont-bother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa inte denna varning igen"])},
  "possible-low-temperature-want-to-continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill fortsätta med de valda värdena?"])},
  "primary-owreg-toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange som primär"])},
  "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion"])},
  "production_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Egen produktionsanvändning ", _interpolate(_named("percents")), " % / sparad ", _interpolate(_named("euros")), " €"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "realtime-at-the-moment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuvarande"])},
  "realtime-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realtidsdata"])},
  "reset_password_mail_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollera användarnamn eller email"])},
  "reset_password_mail_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En länk där du kan återställa ditt lösenord har skickats till din email"])},
  "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rum"])},
  "room-view-temperature-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturinställningar"])},
  "save-to-other-rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara även för andra rum"])},
  "save-to-other-rooms-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara även för valda rum"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sänd"])},
  "set_ilp_fanspeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläkthastighet"])},
  "set_ilp_horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vågrät"])},
  "set_ilp_mode_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiskt läge"])},
  "set_ilp_mode_cool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kylningsläge"])},
  "set_ilp_mode_heat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppvärmningsläge"])},
  "set_ilp_vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lodrät"])},
  "settings-apartment-info-emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post för varningsmeddelanden"])},
  "settings-apartment-info-emails-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(skriv varje mailadress på en separat rad)"])},
  "settings-apartment-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Husinformation"])},
  "settings-apartment-streetaddess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress"])},
  "settings-apartment-wifi-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord för Wi-Fi"])},
  "settings-apartment-wifi-ssid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi SSID"])},
  "settings-change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändra lösenord"])},
  "settings-contract-c-per-kwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c/kWh"])},
  "settings-contract-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dag"])},
  "settings-contract-energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energi"])},
  "settings-contract-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natt"])},
  "settings-contract-pricing-generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allmänt pris"])},
  "settings-contract-pricing-market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marknadspris"])},
  "settings-contract-pricing-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nattpris"])},
  "settings-contract-pricing-season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säsongspris"])},
  "settings-contract-sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försäljning"])},
  "settings-contract-season-another-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra tider"])},
  "settings-contract-season-winter-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinter"])},
  "settings-contract-spot-margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marginal"])},
  "settings-contract-spot-provision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marginal"])},
  "settings-contract-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Överföring"])},
  "settings-contract-use-spot-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Använd spot kontroll"])},
  "settings-electrical-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elavtal"])},
  "settings-heater-owreg-fault-pulse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensorskada pulsförhållande"])},
  "settings-heater-owreg-local-guide-temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltillståndstemperatur"])},
  "settings-heater-owreg-protect-temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max golvtemperatur"])},
  "settings-heatpump-calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temp. kalibrering"])},
  "settings-heatpump-lowest-operating-temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägsta användningstemp."])},
  "settings-heatpump-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
  "settings-heatpump-other-heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annan uppvärmning"])},
  "settings-heatsources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värmekällor"])},
  "settings-heatsources-list-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värmekälla namn"])},
  "settings-profiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiler"])},
  "settings-profiles-name-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn för normalprofil"])},
  "settings-profiles-name-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn för sparprofil"])},
  "settings-room-autoheat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förutseende uppvärmning"])},
  "settings-room-co2-alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO<sub>2</sub>-gräns"])},
  "settings-room-heater-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värmare typ"])},
  "settings-room-heating-algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppvärmningsmetod"])},
  "settings-room-heatpump-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftvärmepumpsinställningar"])},
  "settings-room-humidity-alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftfuktighetens larmnivå"])},
  "settings-room-hysteresis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hysteresis"])},
  "settings-room-max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max"])},
  "settings-room-min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
  "settings-room-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn på rum"])},
  "settings-room-power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppvärmningseffekt"])},
  "settings-room-primary-heat-source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huvudformen för uppvärmning"])},
  "settings-room-temperature-alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturens larmnivå"])},
  "settings-solar-optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar optimering"])},
  "settings-solar-optimization-instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definiera i vilken ordning OptiWatti ska använda överproduktion."])},
  "settings-spot-control-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot kontroll inställningar"])},
  "settings-stepsize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justeringsprecision"])},
  "settings-stepsize-lower-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedre gräns"])},
  "settings-stepsize-precision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precision"])},
  "settings-switches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brytare"])},
  "settings-switches-list-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brytarens namn"])},
  "settings-user-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress"])},
  "settings-user-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
  "settings-user-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "settings-user-firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förnamn"])},
  "settings-user-lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efternamn"])},
  "settings-user-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "settings-user-zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postnummer"])},
  "short-day-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sö"])},
  "short-day-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Må"])},
  "short-day-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti"])},
  "short-day-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On"])},
  "short-day-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "short-day-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
  "short-day-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lö"])},
  "short-day-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sö"])},
  "show-electricity-prices-on-graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa totalt elpris på förbrukningsdiagram"])},
  "spot-control-intro-cheaper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), " billigare, höjs måltemperaturen med ", _interpolate(_named("degree"))])},
  "spot-control-intro-expensive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), " dyrare, sänks måltemperaturen med ", _interpolate(_named("degree"))])},
  "spot-control-lower-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot kontrollerad temperatur undre gräns"])},
  "spot-control-price-brackets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisklasser"])},
  "spot-control-price-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om elpriset per timme är i genomsnitt:"])},
  "spot-control-profile1-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optiwatti Klassisk spot-styrning betonar komfort och temperaturförändringarna är måttliga.\n<br><br>\nOptiWatti sänker tillfälligt den angivna rumstemperaturen för de timmar som är dyrare än det dagliga genomsnittspriset och ökar de timmar som är billigare än det dagliga genomsnittet."])},
  "spot-control-profile2-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OptiWatti Besparingar spot-styrning betonar besparingar på bekostnad av komfort.\n<br><br>\nOptiWatti sänker tillfälligt den angivna rumstemperaturen för de timmar som är dyrare än det dagliga genomsnittspriset och ökar de timmar som är billigare än det dagliga genomsnittet."])},
  "spot-control-profile3-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OptiWatti Extrem spot-styrning betonar endast besparingar och temperaturförändringarna är stora.\n<br><br>\nOptiWatti sänker tillfälligt den angivna rumstemperaturen för de timmar som är dyrare än det dagliga genomsnittspriset och ökar de timmar som är billigare än det dagliga genomsnittet."])},
  "spot-control-profile4-help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertinställningarna baseras på användardefinierade elprisklasser som bestämmer reglerna för hur rumstemperatur målen justeras timme för timme.:\n\n• Användaren kan definiera 3 prisklasser för elpriset (Överlåtelsepris + energi + skatt)\n\n• För varje prisklass väljer man ur 'Sänk minst'-fältet önskat gradtal, och även ytterligare inställning för temperaturfall: Inga åtgärder, Mild justering, Måttlig justering och Brant fall.\n\n• Tilläggsjusteringen är relativ till dagsgenomsnittet"])},
  "spot-control-profile4-help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optiwatti justerar timme för timme temporärt rumstemperaturen enligt följande.\n\n1. Prisklassen väljs efter timpriset.\n\n2. Rummets måltemperatur sänks med den mängd som ställts in i 'Sänk minst'-fältet.\n\n3. Regleringen som valts i prisklassens extra kontrollfält sänker den önskade temperaturen dom timmar betydligt dyrare  än det dagliga medeltalet. I den billigaste priskategorin, för timmar betydligt billigare än genomsnittet, höjs också måltemperaturen."])},
  "spot-control-range-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen spot kontroll"])},
  "spot-control-range-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mild"])},
  "spot-control-range-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måttlig"])},
  "spot-control-range-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brant"])},
  "spot-control-tab1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassisk"])},
  "spot-control-tab2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besparingar"])},
  "spot-control-tab3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrem"])},
  "spot-control-tab4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert"])},
  "spot-control-tab4-energy-price-brackets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energiprisklasser"])},
  "spot-control-tab4-temperature-variations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturvariationsgränser inom prisklasser"])},
  "spot-control-temperature-variations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturvariationsgränser"])},
  "spot-control-upper-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot kontrollerad temperatur övre gräns"])},
  "spot-drop-atleast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sänk minst"])},
  "spot-no-spot-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen spot kontroll"])},
  "spot-price-and-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und över"])},
  "spot-price-below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under"])},
  "spot-price-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minst"])},
  "subtitle-last-connection-since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["senaste anslutningen"])},
  "switch-alwaysoff-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBS: Brytaren är avslagen"])},
  "switch-alwayson-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBS: Brytaren är konstant påslagen"])},
  "switch-common-name-carheater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biluppvärmning"])},
  "switch-common-name-generalswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brytare"])},
  "switch-common-name-heatsourceswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värmekälla"])},
  "switch-common-name-movementsensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rörelsesensor"])},
  "switch-common-name-waterheater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varmvattenberedare"])},
  "switch-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrol"])},
  "switch-controltype-name-alwaysoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Av"])},
  "switch-controltype-name-alwayson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontinuerlig"])},
  "switch-controltype-name-cheaphours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förmånliga timmar"])},
  "switch-controltype-name-expensivehours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dyra timmar"])},
  "switch-controltype-name-forceon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På"])},
  "switch-controltype-name-ignitiontimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starttider"])},
  "switch-controltype-name-solarhours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SV: Solar Hours"])},
  "switch-controltype-name-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timmar"])},
  "switch-expensivehours-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange hur många av de dyraste timmarna per dag som skall undvikas"])},
  "switch-for-sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställningsbar"])},
  "switch-forceon-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OBS: Brytaren är påslagen till slutet av innevarande dygn"])},
  "switch-on-immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppla på direkt"])},
  "switch-on-immediately-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill slå av brytaren?"])},
  "tempwish-follows-owreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golvgivare justerar önskad temperatur (OwReg Termostat)"])},
  "tempwish-follows-owsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumsgivare justerar önskad temperatur"])},
  "terms-agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkänn"])},
  "terms-reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
  "text-electricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektrisk"])},
  "text-water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatten"])},
  "tooltip-settings-heater-owreg-fault-pulse": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om golvtemperaturen inte erhålls från givaren körs golvvärmen med ett pulsförhållande (0-50%). Det rekommenderade maxvärdet är ", _interpolate(_named("limit")), " %. Du kan överskrida rekommendationen, men på egen risk."])},
  "tooltip-settings-heater-owreg-local-guide-temp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vilken temperatur (0-30 °C) är golvet inställt på i en situation där centralenheten inte kan kontaktas. Det rekommenderade maxvärdet är ", _interpolate(_named("limit")), " °C. Du kan överskrida rekommendationen, men på egen risk."])},
  "tooltip-settings-heater-owreg-protect-temp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Max golvtemperatur mellan 0-30 °C. Det rekommenderade maxvärdet är ", _interpolate(_named("limit")), " °C. Du kan överskrida rekommendationen, men på egen risk."])},
  "undefined-plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odefinierad"])},
  "undefined-singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odefinierad"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarnamn"])},
  "vat0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOMS 0 %"])},
  "vat24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOMS 24 %"])},
  "view-apartments-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj hus"])},
  "view-consumption-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förbrukning"])},
  "view-energycost-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energikostnad"])},
  "view-help-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruktioner"])},
  "view-production-and-consumption-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion / Förbrukning"])},
  "view-profiles-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturinställningar"])},
  "view-settings-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar"])},
  "view-switches-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brytare"])},
  "warning-dont-bother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa inte det här igen"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecka"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["År"])}
}
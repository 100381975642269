/*

    Primevue component localisations
    Finnish, English, Swedish

*/
const primelocales = {
    en: {
        startsWith: 'Starts with',
        contains: 'Contains',
        notContains: 'Not contains',
        endsWith: 'Ends with',
        equals: 'Equals',
        notEquals: 'Not equals',
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        clear: 'Clear',
        apply: 'Apply',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Yes',
        reject: 'No',
        choose: 'Choose',
        upload: 'Upload',
        cancel: 'Cancel',
        dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        today: 'Today',
        weekHeader: 'Wk',
        firstDayOfWeek: 1,
        dateFormat: 'dd.mm.yy',
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Enter a password',
        emptyFilterMessage: 'No results found',
        emptyMessage: 'No available options'
    },
    fi: {
        startsWith: 'Alkaa',
        contains: 'Sisältää',
        notContains: 'Ei sisällä',
        endsWith: 'Loppuu',
        equals: 'Yhtäsuuri kuin',
        notEquals: 'Ei yhtäsuuri kuin',
        noFilter: 'Ei suodatinta',
        lt: 'Vähemmän kuin',
        lte: 'Vähemmän tai yhtäsuuri kuin',
        gt: 'Suurempi kuin',
        gte: 'Suurempi tai yhtäsuuri kuin',
        dateIs: 'Päiväys on',
        dateIsNot: 'Päiväys ei ole',
        dateBefore: 'Päiväys on ennen',
        dateAfter: 'Päiväys on jälkeen',
        clear: 'Tyhjennä',
        apply: 'Lisää',
        matchAll: 'Täsmää kaikki',
        matchAny: 'Täsmää mikä tahansa',
        addRule: 'Lisää sääntö',
        removeRule: 'Poista sääntö',
        accept: 'Kyllä',
        reject: 'Ei',
        choose: 'Valitse',
        upload: 'Lähetä',
        cancel: 'Keskeytä',
        dayNames: ["Sunnuntai", "Maanantai", "Tiistai", "Keskiviikko", "Torstai", "Perjantai", "Lauantai"],
        dayNamesShort: ["Sun", "Maa", "Tii", "Kes", "Tor", "Per", "Lau"],
        dayNamesMin: ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
        monthNames: ["Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu", "Kesäkuu", "Heinäkuu", "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"],
        monthNamesShort: ["Tammi", "Helmi", "Maalis", "Huhti", "Touko", "Kesä", "Heinä", "Elo", "Syys", "Loka", "Marras", "Joulu"],
        today: 'Tänään',
        weekHeader: 'Vk',
        firstDayOfWeek: 1,
        dateFormat: 'dd.mm.yy',
        weak: 'Heikko',
        medium: 'Kohtalainen',
        strong: 'Vahva',
        passwordPrompt: 'Syötä salasana',
        emptyFilterMessage: 'Ei tuloksia',
        emptyMessage: 'Ei vaihtoehtoja'
    },
    sv: {
        startsWith: 'Starts with',
        contains: 'Contains',
        notContains: 'Not contains',
        endsWith: 'Ends with',
        equals: 'Equals',
        notEquals: 'Not equals',
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        clear: 'Clear',
        apply: 'Apply',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Ja',
        reject: 'Nej',
        choose: 'Choose',
        upload: 'Upload',
        cancel: 'Cancel',
        dayNames: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"],
        dayNamesShort: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
        dayNamesMin: ["Sö", "Må", "Ti", "On", "To", "Fr", "Lö"],
        monthNames: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        today: 'I dag',
        weekHeader: 'V',
        firstDayOfWeek: 1,
        dateFormat: 'dd.mm.yy',
        weak: 'Svag',
        medium: 'Medium',
        strong: 'Stark',
        passwordPrompt: 'Skriv in ett lösenord',
        emptyFilterMessage: 'Inga resultat funna',
        emptyMessage: 'Inga tillgängliga alternativ'
    },
}

export { primelocales }
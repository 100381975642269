/*

    moduleTemperatureProfiles.js

    Retrieves temperature profiles from API /temperatureprofilename/ and serves them to UI when needed through getters

    Actions uset to set and save temperatureprofilename changes to backend

*/


import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';

const http = axios.create({ // Use cache to reduce calls to backend. These don't change that often
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: true })
});

http.defaults.headers.common = axios.defaults.headers.common; // Add authorization

// Profiles data
export default {
    namespaced: true,
    state() {
        return {
            data: []
        }
    },
    mutations: {

        SET_PROFILES(state, data) {
            state.data = data;
        },
        // eslint-disable-next-line no-unused-vars
        UPDATE_DATA(state, data) {
            // Find object index and replace
            state.data[state.data.findIndex(prof => prof.id == data.id)] = data;
        }
    },
    actions: {
        getProfiles({ commit }, apartmentid) {
            return new Promise((resolve, reject) => {
                http.get(process.env.VUE_APP_OW_API_URL + 'temperatureprofilename/?apartment=' + apartmentid)
                    .then(response => {
                        commit('SET_PROFILES', response.data.results);
                        resolve(response);
                    }).catch((error) => {
                        reject(error);
                    })
            });
        },
        updateTemperatureProfiles({ commit }, data) {
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(data)) {
                var element_id = value.id;
                delete value.id;

                http.patch(process.env.VUE_APP_OW_API_URL + 'temperatureprofilename/' + element_id + '/', value)
                    .then(response => {
                        commit('UPDATE_DATA', response.data);
                    })
                    .catch(() => {}); // Pass to global error handler
            }
        }
    },
    getters: {
        getUserChangeableProfiles(state) {
            // return profiles 1 & 2 for settings dialog
            return state.data.filter(function(obj) { return obj.profile == 1 || obj.profile == 2 });
        },
        getAllProfiles(state) {
            return state.data
        }
    }
}
<template>
  <div class="notfound">
    Wrong URL!
  </div>
</template>

<script>
/*

  views/NotFound.vue

  placeholder for 404. Should redirect to Apartments view.
  
*/

export default {
  name: 'NotFound',
  mounted() {
      console.log('NotFound - wrong url');
      this.$router.push({name: "Apartments"});
    }
}

</script>

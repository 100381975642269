/*

    moduleAPISchema.js

    Retrieves schema from API /schema/ and serves them to UI when needed through getters
    Mainly used to determine the maxLength of form fields at the moment

*/

import axios from 'axios';

// API Schema
export default {
    namespaced: true,
    state() {
        return {
            currentcomponent: null,
            schema: []
        }
    },
    mutations: {
        SET_SCHEMA(state, data) {
            state.schema = data;
        },
        SET_SCHEMA_CURRENTCOMPONENT(state, current) {
            state.currentcomponent = current;
        }
    },
    actions: {
        // Error handling!
        loadSchema({ commit }) {
            axios.get(process.env.VUE_APP_OW_API_URL + 'schema/?format=json')
                .then(response => {
                    commit('SET_SCHEMA', response.data)
                })
        },

        getComponentSchema({ state }, componentname) {
            return state.schema.components.schemas[componentname];
        },
        setSchemaCurrentComponent({ commit }, componentname) {
            commit('SET_SCHEMA_CURRENTCOMPONENT', componentname);
        },
        getMaxLength({ state }, { id, defaultvalue }) {
            var componentschema = state.schema.components.schemas[state.currentcomponent];

            if ((typeof(componentschema) != 'undefined') && (typeof(componentschema.properties) != 'undefined')) {
                if (typeof(componentschema.properties[id]) != 'undefined' && typeof(componentschema.properties[id].maxLength) != 'undefined') {
                    return componentschema.properties[id].maxLength;
                } else {
                    return defaultvalue;
                }
            } else {
                return defaultvalue;
            }
        }
    },
    getters: {}
}
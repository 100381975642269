export default {
  "advanced-priority-target-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced selection"])},
  "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air"])},
  "apartment-profile-component-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change profile"])},
  "apartment-profile-component-title-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change new profile to the whole house"])},
  "apartment-profile-timers-component-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By pressing the + button you can add schedules to your house temperature profiles."])},
  "apartment-profile-timers-component-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedules"])},
  "btn-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "btn-close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "btn-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "btn-copy-to-other-rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to other rooms"])},
  "btn-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "btn-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "btn-reset-to-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset to default"])},
  "btn-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "btn-save-and-copy-to-other-rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and copy"])},
  "change-password-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "chart-adjusted-tempwish-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Adjusted Temperature Setting"])},
  "chart-allpowers-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total consumption"])},
  "chart-apartmentpowers-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption (House)"])},
  "chart-button-title-allpowers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "chart-button-title-apartmentpowers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
  "chart-button-title-co2limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO<sub>2</sub> limit"])},
  "chart-button-title-energycost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric energy"])},
  "chart-button-title-energytax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity tax"])},
  "chart-button-title-heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating"])},
  "chart-button-title-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In"])},
  "chart-button-title-other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "chart-button-title-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out"])},
  "chart-button-title-phases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phases"])},
  "chart-button-title-powers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
  "chart-button-title-production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
  "chart-button-title-sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
  "chart-button-title-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting"])},
  "chart-button-title-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "chart-button-title-totalcost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cost"])},
  "chart-button-title-transfercost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
  "chart-button-title-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
  "chart-button-title-vat-ele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT (Energy)"])},
  "chart-button-title-vat-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT (Transfer)"])},
  "chart-consum_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption"])},
  "chart-heatin_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating"])},
  "chart-humidity-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidity (Indoor)"])},
  "chart-indoortemps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature (In - Air)"])},
  "chart-other_consum_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other consumption"])},
  "chart-out-humidity-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidity (Out)"])},
  "chart-out-temps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature (Out)"])},
  "chart-owregtemps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature (In - Floor)"])},
  "chart-powers-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption (Room)"])},
  "chart-produc_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
  "chart-sold_wh-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold"])},
  "chart-temps-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature (In)"])},
  "chart-tempwish-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature Setting"])},
  "chartdata_consumption_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption L1"])},
  "chartdata_consumption_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption L2"])},
  "chartdata_consumption_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption L3"])},
  "chartdata_production_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production L1"])},
  "chartdata_production_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production L2"])},
  "chartdata_production_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production L3"])},
  "check-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the contract settings"])},
  "choose-house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose house"])},
  "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption"])},
  "consumption-calendar-7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 days"])},
  "consumption-calendar-30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 30 days"])},
  "consumption-calendar-period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
  "copy-selected-to-other-rooms-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy selected settings to selected rooms"])},
  "copy-to-other-rooms-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy settings to selected rooms"])},
  "copy-to-other-rooms-dialog-owreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The values ​​are stored in the current OwReg Thermostat and copied to all OwReg Thermostats in the rooms selected below."])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "dialog-settings-room-advanced-heating-selection-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can define the types of heaters and select individual heaters as the main heaters in the room."])},
  "dialog-settings-room-advanced-heating-selection-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Control"])},
  "dialog-settings-room-owreg-settings-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set the default values ​​below for the operation of the OwReg Thermostat in different situations. Press the question mark icon to get a more detailed description of each setting."])},
  "dialog-settings-room-owreg-settings-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OwReg Thermostat Settings"])},
  "electricity-price-tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity price"])},
  "energycost-consumption-so-far": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total cost in the period so far"])},
  "energycost-cumulative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cost"])},
  "energycost-estimated-usage-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Estimated amount of energy <b>consumed for heating</b> during the period ", _interpolate(_named("usage")), ".<br>Total price ", _interpolate(_named("cost")), ", average price ", _interpolate(_named("average_cost")), "."])},
  "energycost-hourly-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly Price"])},
  "energycost-usage-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The amount of energy <b>bought</b>, measured by OptiWatti during the period ", _interpolate(_named("usage")), ".<br>Total price ", _interpolate(_named("cost")), ", average price ", _interpolate(_named("average_cost")), "."])},
  "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
  "for_sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For sale"])},
  "forecast-not-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weather forecast is not currently available."])},
  "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "forgotten-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email and we will send a link that will allow to change your password.<br/><br/>If you have forgotten your password, please contact our customer support, tel. 029&nbsp;1701&nbsp;007"])},
  "forgotten-password-field-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "forsale-dialog-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Additional service available. Please contact customer service at ", _interpolate(_named("salesemail")), " / +358 (0)29 1701 007"])},
  "guidedsetup-alldone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2>Congratulations</h2>All settings are set."])},
  "heatpump-mode-auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic"])},
  "heatpump-mode-cool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooling"])},
  "heatpump-mode-heat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating"])},
  "heatpump-mode-heatoff-confirmation-option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the whole house?"])},
  "heatpump-mode-heatoff-confirmation-option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["only this room?"])},
  "heatpump-mode-heatoff-confirmation-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chosen more requires the heating to be turned off.<br/>Do you wish to turn the heat off from"])},
  "heatpump-mode-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Off"])},
  "heatsource-curve-in-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water"])},
  "heatsource-curve-out-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outside"])},
  "heatsource-curve-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjustment curve"])},
  "heatsource-curve-xaxis-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature outside / °C"])},
  "heatsource-curve-yaxis-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water temperature / °C"])},
  "ignitiontimes-temperature-dialog-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating limits in different temperatures"])},
  "in-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check email"])},
  "invalid-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link has expired"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "localized-profile-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooling"])},
  "localized-profile-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating off"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "moments-energycost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price of energy at the moment under review"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "move-only-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes by the hour"])},
  "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "new-password-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
  "new-version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New version available."])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night"])},
  "no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
  "none-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None selected"])},
  "note-undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])},
  "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection problem"])},
  "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
  "old-password-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password is wrong"])},
  "oldpassword-null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the old password"])},
  "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
  "ow-alert-code-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace the room unit battery"])},
  "ow-alert-code-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace the heat pump controller battery"])},
  "ow-alert-code-1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room temperature below limit value"])},
  "ow-alert-code-1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room temperature above limit value"])},
  "ow-alert-code-1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room humidity below limit value"])},
  "ow-alert-code-1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room humidity above limit value"])},
  "ow-alert-code-2001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement data is out of date"])},
  "ow-alert-code-2002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poor connection to sensor"])},
  "ow-alert-code-2003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poor connection to heater"])},
  "ow-alert-code-2004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No connection to heater"])},
  "ow-alert-code-2005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water leakage detected"])},
  "ow-alert-code-2006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OwReg device's floor temperature sensor in error"])},
  "ow-alert-code-2602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2 levels over the limit"])},
  "ow-alert-code-5000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contact to central unit"])},
  "ow-alert-code-5001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email address"])},
  "ow-alert-code-5002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity contract information missing"])},
  "ow-alert-code-5003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movement detected"])},
  "ow-confirm-profile-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to change this setting to all the rooms?"])},
  "ow-error-access-forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access forbidden"])},
  "ow-error-token-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session has expired. Please login again."])},
  "ow-guided-setup-moistalert-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set humidity limits for all rooms."])},
  "ow-guided-setup-tempalert-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set temperature limits for all rooms."])},
  "ow-help-guided-setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guided setup"])},
  "ow-help-terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
  "ow-helper-setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup"])},
  "ow-helper-skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore"])},
  "ow-helper-text-algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Predictive heating</h4><p>Use predictive heating to get optimal temperatures.</p>"])},
  "ow-helper-text-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Electricity contract</h4><p>Check that your electricity contract setting is set to reflect your current contract.</p>"])},
  "ow-helper-text-moistalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Humidity alarm limits</h4><p>Set limits for room humidity. You will receive a notification if humidity goes outside these limits.</p>"])},
  "ow-helper-text-tempalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h4>Temperature alarm limits</h4><p>Set limits for room temperature. You will receive a notification if temperature goes outside these limits.</p>"])},
  "ow-http-error-400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad request"])},
  "ow-no-solarpanels": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>OptiWatti energy analysis is part of OptiWatti Solar module.<br>\nInquiries and orders: ", _interpolate(_named("salesemail")), " / +358 (0)29 1701 007</p>"])},
  "ow-timer-dialog-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduling"])},
  "owreg-err-dutycycle-over-suggested": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The value exceeds the recommended limit (", _interpolate(_named("limit")), " %)!"])},
  "owreg-tmax-offline-over-suggested": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The value exceeds the recommended limit (", _interpolate(_named("limit")), " °C)!"])},
  "owreg-tmax-over-suggested": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The value exceeds the recommended limit (", _interpolate(_named("limit")), " °C)!"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "password-changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been changed"])},
  "password-link-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A link that lets you reset your password has been sent to your email"])},
  "password-not-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is not valid"])},
  "password-test-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must be at least 10 characters long and consist of uppercase and lowercase letters and numbers."])},
  "password-too-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is too short"])},
  "passwords-dont-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
  "possible-bad-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to the zero-price fields of the electricity contract, the figures may not match."])},
  "possible-low-temperature-alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["NOTICE! Temperatures may go below the recommended ", _interpolate(_named("limit")), " temperature with the set values!"])},
  "possible-low-temperature-alert-rooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["NOTICE! In some rooms the temperatures may go below the recommended ", _interpolate(_named("limit")), " temperature with the set values!"])},
  "possible-low-temperature-dont-bother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not show this warning again"])},
  "possible-low-temperature-want-to-continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to continue with the selected values?"])},
  "primary-owreg-toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as primary"])},
  "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
  "production_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Own production usage ", _interpolate(_named("percents")), " % / saved ", _interpolate(_named("euros")), " €"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "realtime-at-the-moment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
  "realtime-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time data"])},
  "reset_password_mail_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check username or email"])},
  "reset_password_mail_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A link that lets you reset your password has been sent to your email"])},
  "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
  "room-view-temperature-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature settings"])},
  "save-to-other-rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save to other rooms"])},
  "save-to-other-rooms-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Also save to selected rooms"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "set_ilp_fanspeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fan speed"])},
  "set_ilp_horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
  "set_ilp_mode_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic mode"])},
  "set_ilp_mode_cool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooling mode"])},
  "set_ilp_mode_heat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating mode"])},
  "set_ilp_vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
  "settings-apartment-info-emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House alerts"])},
  "settings-apartment-info-emails-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(type each email on a separate line)"])},
  "settings-apartment-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House name"])},
  "settings-apartment-streetaddess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street address"])},
  "settings-apartment-wifi-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi password"])},
  "settings-apartment-wifi-ssid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi SSID"])},
  "settings-change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "settings-contract-c-per-kwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c/kWh"])},
  "settings-contract-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "settings-contract-energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy"])},
  "settings-contract-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night"])},
  "settings-contract-pricing-generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General price"])},
  "settings-contract-pricing-market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market price"])},
  "settings-contract-pricing-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night price"])},
  "settings-contract-pricing-season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Season price"])},
  "settings-contract-sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
  "settings-contract-season-another-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other times"])},
  "settings-contract-season-winter-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter day"])},
  "settings-contract-spot-margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin"])},
  "settings-contract-spot-provision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin"])},
  "settings-contract-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
  "settings-contract-use-spot-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use spot control"])},
  "settings-electrical-contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity contract"])},
  "settings-heater-owreg-fault-pulse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor damage pulse ratio"])},
  "settings-heater-owreg-local-guide-temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fault condition temperature"])},
  "settings-heater-owreg-protect-temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum floor temperature"])},
  "settings-heatpump-calibration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calibration"])},
  "settings-heatpump-lowest-operating-temp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest operating temp."])},
  "settings-heatpump-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "settings-heatpump-other-heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other heating"])},
  "settings-heatsources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heat sources"])},
  "settings-heatsources-list-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the heat source"])},
  "settings-profiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiles"])},
  "settings-profiles-name-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal profile name"])},
  "settings-profiles-name-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy profile name"])},
  "settings-room-autoheat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predictive heating"])},
  "settings-room-co2-alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO<sub>2</sub> limit"])},
  "settings-room-heater-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heater type"])},
  "settings-room-heating-algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating method"])},
  "settings-room-heatpump-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AC settings"])},
  "settings-room-humidity-alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidity alarm limits"])},
  "settings-room-hysteresis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hysteresis"])},
  "settings-room-max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max"])},
  "settings-room-min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
  "settings-room-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room name"])},
  "settings-room-power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating power"])},
  "settings-room-primary-heat-source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The main form of heating"])},
  "settings-room-temperature-alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature alarm limits"])},
  "settings-solar-optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar optimization"])},
  "settings-solar-optimization-instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define in which order OptiWatti should use overproduction."])},
  "settings-spot-control-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot control settings"])},
  "settings-stepsize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control accuracy"])},
  "settings-stepsize-lower-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower limit"])},
  "settings-stepsize-precision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precision"])},
  "settings-switches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
  "settings-switches-list-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch label"])},
  "settings-user-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street address"])},
  "settings-user-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "settings-user-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "settings-user-firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "settings-user-lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "settings-user-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "settings-user-zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIP code"])},
  "short-day-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su"])},
  "short-day-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
  "short-day-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu"])},
  "short-day-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We"])},
  "short-day-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Th"])},
  "short-day-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
  "short-day-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])},
  "short-day-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su"])},
  "show-electricity-prices-on-graphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show total electricity price on consumption graphs"])},
  "spot-control-intro-cheaper": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), " cheaper, the target temperature is increased by ", _interpolate(_named("degree"))])},
  "spot-control-intro-expensive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), " more expensive, the target temperature is decreased by ", _interpolate(_named("degree"))])},
  "spot-control-lower-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot controlled temperature lower limit"])},
  "spot-control-price-brackets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price brackets"])},
  "spot-control-price-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the hourly price compared to daily average is:"])},
  "spot-control-profile1-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optiwatti Classic spot control emphasizes comfort and temperature changes are moderate. \n<br><br>\nOptiWatti temporarily decreases targeted room temperature for the hours more expensive than daily average price and increases for hours cheaper than daily average."])},
  "spot-control-profile2-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OptiWatti Savings spot control emphasizes savings more at the cost of comfort. \n<br><br>\nOptiWatti temporarily decreases targeted room temperature for the hours more expensive than daily average price and increases for hours cheaper than daily average."])},
  "spot-control-profile3-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OptiWatti Extreme spot control emphasizes only savings and temperature changes are large. \n<br><br>\nOptiWatti temporarily decreases targeted room temperature for the hours more expensive than daily average price and increases for hours cheaper than daily average."])},
  "spot-control-profile4-help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert Settings are based on user defined electricity price classes defining the rules how room temperature targets are adjusted hour by hour. :\n\n• User can define 3 price classes for electricity price (transfer price + energy + taxes)\n\n• For each price class there is a selection ‘Decrease at least’-field and additional setting for temperature drop: No actions, Gentle adjustment, Moderate adjustment and Steep drop.\n\n• The additional adjustment is relative to daily average."])},
  "spot-control-profile4-help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OptiWatti adjust temporarily hour by hour the room target temperature as follows:\n\n1. The price class is selected according to the hourly price. \n\n2. The room target temperature is decreased by the amount set in \"Decrease at least\"-field \n\n3. The control selected in the price class additional control field lowers the desired temperature for hours significantly more expensive than the daily average price. In the most affordable price category, for hours significantly cheaper than the average price, the temperature request is also increased."])},
  "spot-control-range-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No spot control"])},
  "spot-control-range-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentle"])},
  "spot-control-range-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderate"])},
  "spot-control-range-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steep"])},
  "spot-control-tab1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classic"])},
  "spot-control-tab2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savings"])},
  "spot-control-tab3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extreme"])},
  "spot-control-tab4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert"])},
  "spot-control-tab4-energy-price-brackets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy price brackets"])},
  "spot-control-tab4-temperature-variations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature adjustment limits in price brackets"])},
  "spot-control-temperature-variations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature variation limits"])},
  "spot-control-upper-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot controlled temperature upper limit"])},
  "spot-drop-atleast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease at least"])},
  "spot-no-spot-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No spot control"])},
  "spot-price-and-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and over"])},
  "spot-price-below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below"])},
  "spot-price-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least"])},
  "subtitle-last-connection-since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last connection"])},
  "switch-alwaysoff-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTE: Switch is OFF"])},
  "switch-alwayson-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTE: Switch is ON"])},
  "switch-common-name-carheater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car heater"])},
  "switch-common-name-generalswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch"])},
  "switch-common-name-heatsourceswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heat source"])},
  "switch-common-name-movementsensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movement sensor"])},
  "switch-common-name-waterheater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waterheater"])},
  "switch-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control"])},
  "switch-controltype-name-alwaysoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Off"])},
  "switch-controltype-name-alwayson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuous"])},
  "switch-controltype-name-cheaphours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy hours"])},
  "switch-controltype-name-expensivehours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expensive hours"])},
  "switch-controltype-name-forceon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On"])},
  "switch-controltype-name-ignitiontimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start times"])},
  "switch-controltype-name-solarhours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN: Solar Hours"])},
  "switch-controltype-name-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
  "switch-expensivehours-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify how many of the most expensive hours per day are avoided"])},
  "switch-for-sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available to order"])},
  "switch-forceon-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTE: Switch is constantly ON for the end of the day"])},
  "switch-on-immediately": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch on immediately"])},
  "switch-on-immediately-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to switch this switch on for the rest of this day?"])},
  "tempwish-follows-owreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor sensor adjusts desired temperature (OwReg Thermostat)"])},
  "tempwish-follows-owsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room sensor adjusts desired temperature"])},
  "terms-agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "terms-reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
  "text-electricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric"])},
  "text-water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water"])},
  "tooltip-settings-heater-owreg-fault-pulse": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If the floor temperature is not obtained from the sensor, the floor heating is run with a pulse ratio (0-50%). The recommended maximum value is ", _interpolate(_named("limit")), " %. You can exceed the recommendation, but at your own risk."])},
  "tooltip-settings-heater-owreg-local-guide-temp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["What temperature (0-30 °C) is the floor set to in a situation where the central unit cannot be contacted. The recommended maximum value is ", _interpolate(_named("limit")), " °C. You can exceed the recommendation, but at your own risk."])},
  "tooltip-settings-heater-owreg-protect-temp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum floor temperature between 0-30 °C. The recommended maximum value is ", _interpolate(_named("limit")), " °C. You can exceed the recommendation, but at your own risk."])},
  "undefined-plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])},
  "undefined-singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "vat0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT 0 %"])},
  "vat24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT 24 %"])},
  "view-apartments-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose house"])},
  "view-consumption-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption"])},
  "view-energycost-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Cost"])},
  "view-help-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
  "view-production-and-consumption-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production / Consumption"])},
  "view-profiles-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature settings"])},
  "view-settings-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "view-switches-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
  "warning-dont-bother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not show this again"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])}
}
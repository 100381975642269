/*

    moduleApartments.js

    Retrieves alerts from API /apartment/ and serves them to UI when needed through getters

*/

import axios from 'axios';

// Apartments data
export default {
    namespaced: true,
    state() {
        return {
            activeApartment: null,
            data: [],
            customertype: { // Current known customer types. This should be transfered to API later
                'FI1': { type: 'b2c', country: 'FI', heat_delivery: 'electric', subtype: null },
                'FI2': { type: 'b2c', country: 'FI', heat_delivery: 'electric', subtype: 'cottage' },
                'FI3': { type: 'b2b', country: 'FI', heat_delivery: 'electric', subtype: null },
                'FI4': { type: 'b2c', country: 'FI', heat_delivery: 'water', subtype: null },
                'FI5': { type: 'b2b', country: 'FI', heat_delivery: 'water', subtype: null },
                'FI6': { type: 'b2c', country: 'FI', heat_delivery: 'hybrid', subtype: null },
                'FI7': { type: 'b2b', country: 'FI', heat_delivery: 'hybrid', subtype: null },
                'SE1': { type: 'b2c', country: 'SE', heat_delivery: 'electric', subtype: null },
                'SE2': { type: 'b2b', country: 'SE', heat_delivery: 'electric', subtype: null },
                'SE4': { type: 'b2c', country: 'SE', heat_delivery: 'water', subtype: null },
                'SE5': { type: 'b2b', country: 'SE', heat_delivery: 'water', subtype: null },
                'SE6': { type: 'b2c', country: 'SE', heat_delivery: 'hybrid', subtype: null },
                'SE7': { type: 'b2b', country: 'SE', heat_delivery: 'hybrid', subtype: null }
            }
        }
    },
    mutations: {
        SET_APARTMENTS(state, data) {
            // Set state data
            state.data = data;
        },
        SET_ACTIVEAPARTMENT(state, apartmentid) {
            // Set active apartment
            state.activeApartment = apartmentid;
        },
        UPDATE_ACTIVEAPARTMENT_DATA(state, data) {
            // Update only data for the active apartment
            var index = state.data.map(apartment => apartment.id).indexOf(state.activeApartment);
            state.data[index] = ({...state.data[index], ...data });
        }
    },
    actions: {
        getApartments({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_OW_API_URL + 'apartment/?page_size=2000')
                    .then(response => {
                        commit('SET_APARTMENTS', response.data.results);
                        resolve(response);
                    }).catch((error) => { reject(error) });
            });
        },
        setActiveApartment({ commit }, apartmentid) {
            commit('SET_ACTIVEAPARTMENT', apartmentid);
        },
        updateActiveApartment({ commit, state }, data) {
            /* This is called from moduleApartment when one apartment is changed to update to global data */
            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_OW_API_URL + 'apartment/' + state.activeApartment + '/', data)
                    .then(response => {
                        commit('UPDATE_ACTIVEAPARTMENT_DATA', data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        reorderApartments({ commit }, data) {
            return new Promise((resolve, reject) => {
                var patchRequests = [];

                data.forEach((element, index) => {
                    element.uiorder = index;

                    patchRequests.push(axios.patch(process.env.VUE_APP_OW_API_URL + 'apartment/' + element.id + '/', { sortorder: index }));
                });

                axios.all(patchRequests)
                    .then(
                        axios.spread((...responses) => {
                            resolve(responses);
                            commit('SET_APARTMENTS', data);
                        })
                    )
                    .catch(errors => {
                        reject(errors);
                    });
            });
        },
    },
    getters: {
        getActiveApartment(state) {
            return state.data.find(apartment => apartment.id === state.activeApartment);
        },
        getActiveApartmentId(state) {
            return state.activeApartment;
        },
        // eslint-disable-next-line no-unused-vars
        getApartmentTZ(state) {
            if (typeof(state) != 'undefined' && typeof(state.activeApartment) != 'undefined' && state.activeApartment != null) {

                if (typeof(state.data.find(apartment => apartment.id === state.activeApartment)) != 'undefined' && typeof(state.data.find(apartment => apartment.id === state.activeApartment).timezone) != 'undefined') {
                    return state.data.find(apartment => apartment.id === state.activeApartment).timezone;
                } else {
                    return "Europe/Helsinki"; // Default missing timezones to Europe/Helsinki
                }

            } else {
                return "Europe/Helsinki"; // Default missing timezones to Europe/Helsinki
            }

        },
        getApartmentFirstDataDate(state) {
            if (typeof(state.activeApartment) != 'undefined' && state.activeApartment != null) {

                if (typeof(state.data.find(apartment => apartment.id === state.activeApartment).first_data) != 'undefined') {
                    return state.data.find(apartment => apartment.id === state.activeApartment).first_data;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        getApartmentExcessEnergyTargets(state) {
            if (state.activeApartment != null) {
                if (typeof(state.data.find(apartment => apartment.id === state.activeApartment).first_data) != 'undefined') {
                    return JSON.parse(state.data.find(apartment => apartment.id === state.activeApartment).excess_energy_targets);
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        getApartmentHeatProductionTypes(state) {
            if (state.activeApartment != null) {
                if (typeof(state.data.find(apartment => apartment.id === state.activeApartment).heat_production_types) != 'undefined') {
                    return state.data.find(apartment => apartment.id === state.activeApartment).heat_production_types;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        getApartmentHeatDeliveryTypes(state) {
            if (state.activeApartment != null) {
                if (typeof(state.data.find(apartment => apartment.id === state.activeApartment).heat_delivery_types) != 'undefined') {
                    return state.data.find(apartment => apartment.id === state.activeApartment).heat_delivery_types;
                } else {
                    return new Array('electric');
                }
            } else {
                return null;
            }
        },
        getApartmentClientType(state) {
            if (state.activeApartment != null) {
                if (typeof(state.data.find(apartment => apartment.id === state.activeApartment).customer_type) != 'undefined') {
                    // TODO Find type from the array based on the customertype
                    // If not found on the list, default is b2c
                    var custype = state.data.find(apartment => apartment.id === state.activeApartment).customer_type;
                    if (state.customertype[custype]) return state.customertype[custype].type;
                    return 'b2c';
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        getApartmentClientCounty(state) {
            if (state.activeApartment != null) {
                if (typeof(state.data.find(apartment => apartment.id === state.activeApartment).customer_type) != 'undefined') {
                    // TODO Find country from the array based on the customertype
                    // If not found on the list default is FI
                    var custype = state.data.find(apartment => apartment.id === state.activeApartment).customer_type;
                    if (state.customertype[custype]) return state.customertype[custype].country;
                    return 'FI';
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }
}
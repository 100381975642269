<template>
    <div v-if="displayMotdBar" class="motd-banner" @click="openMotdDialog">
        {{ motd_from_wp.title }}
    </div>

    <Dialog :header="motd_from_wp.title" v-model:visible="displayMotdDialog" :style="{width: '50vw'}" class="motddialog">
        <p v-html="sanitizeMessage()"></p>
        <template #footer>
            <Button label="OK" icon="pi pi-check" @click="closeMotdDialog" autofocus />
        </template>
    </Dialog>
</template>

<script>
/*

    MotdBar.vue

    If Message of the Day / Disturbance notice are received from the Wordpress
    Shows Orange banner on the top of the screen with the disturbance title
    If clicked, shows dialog with the disturbance message

    Still contains also old code remmed out to retrieve the motd.json from optiwatti.fi (remove once verified not needed)

*/

import axios from 'axios';
import sanitizeHtml from 'sanitize-html';

var motd_refreshinterval_millisecs = 300000; // 300000 = 5 min

const motdapi = axios.create( {
  transformRequest: [(data, headers) => {
    delete headers.common.Authorization;
    return data;
  }],
});

export default {
  name: 'motd',
  data() {
    return {
        displayMotdBar: false,
        displayMotdDialog: false,
        myInterval: null,
        /*
        motddata: {
            "fi": {
                "title": "",
                "message": ""
            },
            "en": {
                "title": "",
                "message": ""
            },
            "sv": {
                "title": "",
                "message": ""
            }
        },
        */
        motd_from_wp: {
            "title": "",
            "message": ""
        }
    };
  },
  mounted: function() {
      this.resetFetch(); // Start the motd check interval from the beginning
  },
  watch: {
      '$i18n.locale': function() {
          this.resetFetch();
      }
  },
  methods: {
    sanitizeMessage() {
        //return sanitizeHtml(this.motddata[this.$i18n.locale].message);
        return sanitizeHtml(this.motd_from_wp.message);
    },
    resetFetch() {
      clearInterval(this.myInterval);
      this.fetchMotd();
      var self = this;

      this.myInterval = setInterval(function() {
          self.fetchMotd();
      }, motd_refreshinterval_millisecs);
    },
    fetchMotd() {

        // Old style from optiwatti.fi
        /*
        axios.get(motd_url, {
            transformRequest: [(data, headers) => {
                delete headers.common.Authorization;
                return data;
            }]
        }).then(response => {
            if(response.status == 200) {
                this.motddata = response.data;

                if(this.motddata.active) {
                    this.displayMotdBar = true;
                }
            } else {
                this.displayMotdBar = false;
            }
        }).catch((error) => { console.log('motd error'); console.log(error);});
        */

        // New style from VUE_APP_OW_MOTD_URL
        // ?lang=en&_fields=id,date,title,content
        motdapi.get(process.env.VUE_APP_OW_MOTD_URL + '?lang=' + this.$i18n.locale + '&_fields=id,date,title,content')
        .then(response => {
            if(response.data.length > 0) {
                console.log(response.data);
                this.motd_from_wp.title = response.data[0].title.rendered;
                this.motd_from_wp.message = response.data[0].content.rendered;
                this.displayMotdBar = true;
                document.body.classList.add('motd');
            } else {
                this.displayMotdBar = false;
                document.body.classList.remove('motd');
            }
        }).catch(()=>{
            this.displayMotdBar = false;
        });

    },
    openMotdDialog() {
        this.displayMotdDialog = true;
    },
    closeMotdDialog() {
        this.displayMotdDialog = false;
    },
  }
}
</script>

<style lang="scss">
    .motd-banner {
        position:fixed;
        top:0;
        left:0;
        right:0;
        color: $motd-fg;
        background-color: $motd-bg;
        text-align: center;
        font-weight:700;
        padding:0.3rem;
        z-index: 1000;
        cursor: pointer;
    }

    .motddialog {
        background-color:#fff;

        .p-dialog-content {
            margin-right:0.5rem;
            margin-bottom:1rem;
        }
    }

    // Custom CSS for mobiles in portrait mode

    .mobile {
        .motd-banner {
            padding: 0.25rem;
            //left: 50%;
            //right: auto;
            //transform: translateX(-50%);
        }
    }

    @media screen and (orientation: portrait) and (max-width:767px) {
        .mobile {
            .motd-banner {
                top:auto;
                bottom:0;
                left:0;
                right:0;
                transform: none;
                padding:0.5rem;
                padding-bottom: max(0.5rem, env(safe-area-inset-bottom));
                z-index:999;
            }

            .motddialog {
                width:90vw !important;
            }
        }

        body.motd {
            .ow-view {
                padding-bottom: 3rem;
            }
        }
    }

</style>
<template>
  <div class="locale-changer">
    <ul>
      <li
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        :data-value="lang.id"
        v-bind:class="$i18n.locale == lang.id ? 'current' : ''"
      >
        <a @click="changeLocale(lang.id)">{{ lang.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
/*
locale-changer: Inserts a list of locales defined below in the data section to a list. Allows users to change the locale of the UI

No props used.

emits: changeLocale - parent will react
*/
import axios from 'axios'
import moment from 'moment';
import { primelocales } from '@/assets/_ow_primevue_locales.js';

export default {
  name: 'locale-changer',
  props: ['forceLanguage'],
  emits: ["changeLocale"],
  data () {
    return {
        langs: [{id:'fi', name:'Suomi'}, {id:'sv', name:'Svenska'}, {id:'en', name:'English'}],
        langcodes: {'en':'en-US', 'fi':'fi-FI', 'sv':'sv-SE'},
    }
  },
  methods: {
      changeLocale(newLocale) {
          this.$root.$emit('changeLocale', newLocale);
          this.$root.$i18n.locale = newLocale; // Force i18n locale to change too
          moment.locale(newLocale); // Force moment locale to change
          localStorage.setItem('owui2-language', newLocale); // Save to local storage
          //axios.defaults.headers.common['Accept-Language'] = this.langcodes[this.$root.$i18n.locale]; // Change default accept-language for xhr calls
          axios.defaults.headers.common['Accept-Language'] = this.$root.$i18n.locale; // Change default accept-language for xhr calls Backend uses only short language codes!
          this.changePrimeVueLocale(newLocale); // Force PrimeVue components locale change
      },

      changePrimeVueLocale(newLocale) {
        this.$root.$primevue.config.locale = primelocales[newLocale];
      }
  },
  watch: {
      forceLanguage: function(newVal) {
          this.changeLocale(newVal);
      }
  }
}
</script>

<style lang="scss">
.locale-changer {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      cursor: pointer;
      letter-spacing: -0.025rem;
      font-weight: 300;

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      &.current {
        font-weight: bold;
      }

      &::before {
        content: "|";
        margin: 0 0.5rem;
        font-weight:300;
      }

      &:first-child {
        &::before {
          content: "";
          margin: 0;
        }
      }
    }
  }
}
</style>
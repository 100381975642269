/*

    moduleAlerts.js

    Retrieves alerts from API /alert/ and serves them to UI when needed through getters

*/
import axios from 'axios';

export default {
    namespaced: true,
    state() {
        // Init state data
        return {}
    },
    mutations: {
        SET_ALERTS(state, data) {
            state.data = data;
        },
    },
    actions: {
        getAlerts({ commit }) {
            return new Promise((resolve, reject) => {
                // Retrieve alerts
                axios.get(process.env.VUE_APP_OW_API_URL + 'alert/')
                    .then(response => {
                        // Set alerts state
                        commit('SET_ALERTS', response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
    },
    getters: {
        getAllAlerts(state) {
            // Return all state data
            return state.data;
        },
        getRoomAlerts: (state) => (roomid) => {
            // Returns only alerts which have room set to argument roomid and are not flagged only_admin
            return state.data.filter(element => element.room === parseInt(roomid) && element.only_admin != true);
        },
        getApartmentAlerts: (state) => (apartmentid) => {
            // Returns only alerts which have apartment set to argument apartmentid and are not flagged only_admin AND room is null!
            return state.data.filter(element => element.apartment === parseInt(apartmentid) && element.only_admin != true && element.room == null);
        },
        getAllApartmentAlerts: (state) => (apartmentid) => {
            // Returns only alerts which have apartment set to argument apartmentid and are not flagged only_admin
            return state.data.filter(element => element.apartment === parseInt(apartmentid) && element.only_admin != true);
        }
    }
}
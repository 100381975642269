import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'

import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import SelectButton from 'primevue/selectbutton';
import ToggleButton from 'primevue/togglebutton';
import Calendar from 'primevue/calendar';
import ProgressSpinner from 'primevue/progressspinner';
import Slider from 'primevue/slider';
import Checkbox from 'primevue/checkbox';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Chart from 'primevue/chart';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import i18n from './i18n'

import axios from 'axios'

const app = createApp(App).use(i18n);

app.use(router);
app.use(store);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);

app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Button', Button);
app.component('Toast', Toast);
app.component('Dialog', Dialog);
app.component('Textarea', Textarea);
app.component('Dropdown', Dropdown);
app.component('SelectButton', SelectButton);
app.component('ToggleButton', ToggleButton);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Calendar', Calendar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Chart', Chart);
app.component('Slider', Slider);
app.component('Checkbox', Checkbox);
app.component('ConfirmDialog', ConfirmDialog);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);

app.directive("tooltip", Tooltip);
app.mount('#app');

// UN-485 Ability hide features
// use this.$hideFeatures.spot2 to check. If true -> HIDE else SHOW.
// eslint-disable-next-line no-unused-vars
const hideFeatures = {'spot2' : false};
app.config.globalProperties.$hideFeatures = hideFeatures;

// reactive state
// Global reactive state used to store access token, username and reasonForLogout
const currentUser = reactive({
    token: null,
    username: null,
    reasonForLogout: null
})

app.config.globalProperties.$currentUser = currentUser;

// Provide go back for the "turn device screen"
document.body.addEventListener('goBackOne', function() {
    router.go(-1);
});


window
    .matchMedia('(orientation: portrait)')
    .addEventListener('change', function(m) {
        if (m.matches) {
            // portrait
            //console.log('portrait match');
            setTimeout(function() {
                document.body.dataset.orientation = window.orientation;
            }, 400);
        } else {
            // landscape
            //console.log('landscape match');
            setTimeout(function() {
                document.body.dataset.orientation = window.orientation;
            }, 400);
        }
    });

window.addEventListener('orientationchange', function() {
    document.body.dataset.orientation = window.orientation;
});

// Provide header height for portrait mobile version for calculations
window.addEventListener('resize', function() {
    //console.log('resize launched');
    var owheaders = document.getElementsByClassName('ow-header');
    if (owheaders.length) {
        document.documentElement.style.setProperty('--ow-header-height', owheaders[0].offsetHeight + "px");
    }

    var currentScrollTop = document.documentElement.scrollTop;
    if (currentScrollTop != 0) {
        document.documentElement.style.setProperty('--ow-fix-height', currentScrollTop + "px");
        document.documentElement.scrollTop = 0;
    }

    document.body.dataset.orientation = window.orientation;
});

window.addEventListener('refreshHeaderHeight', function() {
    //console.log('refreshHeaderHeight');
    setTimeout(function() {
        var owheaders = document.getElementsByClassName('ow-header');
        if (owheaders.length) {
            // eslint-disable-next-line no-unused-vars
            var newHeight = owheaders[0].offsetHeight; // Needs this here, otherwise simulator not update
            //console.log(owheaders[0].offsetHeight);

            document.documentElement.style.setProperty('--ow-header-height', owheaders[0].offsetHeight + "px");
        }
    }, 500);
});

document.addEventListener('DOMContentLoaded', function() {

    //console.log('DOM loaded');
    setTimeout(function() {
        var owheaders = document.getElementsByClassName('ow-header');
        if (owheaders.length) {
            document.documentElement.style.setProperty('--ow-header-height', owheaders[0].offsetHeight + "px");
        }
        document.body.dataset.orientation = window.orientation;
    }, 500);

})

// Simple access control for now! Change these when auth is desided!
// eslint-disable-next-line no-unused-vars
export function canUserAccess(to) {
    if (typeof(localStorage.token) != 'undefined' && typeof(localStorage.token) == 'string') {
        app.config.globalProperties.$currentUser.token = localStorage.token;
        axios.defaults.headers.common['Authorization'] = localStorage.token;
    }

    // Dummy test
    if (app.config.globalProperties.$currentUser.token !== null) {
        return true;
    } else {
        return false;
    }
}

export function clearToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('vuex'); // Clear persistant Vuex storage
    app.config.globalProperties.$currentUser.token = null;
    router.push('/');
}

export function checkIfLoggedIn() {
    if (typeof(app.config.globalProperties.$currentUser) == 'undefined') return false;
    if (app.config.globalProperties.$currentUser.token !== null) {
        return true;
    } else {
        return false;
    }
}
/*

    moduleUISettings.js

    Retrieves UI settings from API /uisetting/ and serves them to UI when needed through getters

    Used in various views/components to save certain UI specific values to the backend such as
    - Temperature Settings stepsize & precision
    - Has user accepted terms & services
    - Has user gone through the guided setup
    -...

*/


// UISettings data
import axios from 'axios';

export default {
    namespaced: true,
    state() {
        return {}
    },
    mutations: {
        SET_UISETTINGS(state, data) {
            state.data = data;
        },
        UPDATE_UISETTING(state, data) {
            // Find with ID from state.data..
            // If found, check type and replace value
            // if not found, push data to state.data

            var objIndex = state.data.findIndex((obj => obj.id == data.id));
            if (objIndex < 0) {
                // Not found, push new to state.data
                state.data.push(data);
            } else {
                // found, check type and replace value
                if (state.data[objIndex].type == data.type) {
                    state.data[objIndex].value = data.value;
                } else {
                    // If type mismatch, let API sort out on next load :)
                }
            }
        }
    },
    actions: {
        getUISettings({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_OW_API_URL + 'uisetting/?page_size=2000')
                    .then(response => {
                        commit('SET_UISETTINGS', response.data.results);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },

        saveUISetting({ commit, state }, { type, value }) {
            return new Promise((resolve, reject) => {
                if (value === false) value = '0';
                if (value === true) value = '1';
                var existing = state.data.find(element => element.type === type);
                var setting_id = '';

                if (typeof(existing) == 'undefined') {
                    // if NOT found use POST
                    axios.post(process.env.VUE_APP_OW_API_URL + 'uisetting/', { 'type': type, 'value': value })
                        .then(response => {
                            commit('UPDATE_UISETTING', response.data);
                            resolve(response);
                        })
                        .catch(error => {
                            reject(error);
                        })
                } else {
                    // If found, use PUT
                    setting_id = existing.id + '/';
                    axios.put(process.env.VUE_APP_OW_API_URL + 'uisetting/' + setting_id, { 'type': type, 'value': value })
                        .then(response => {
                            commit('UPDATE_UISETTING', response.data);
                            resolve(response);
                        })
                        .catch(error => {
                            reject(error);
                        })
                }
            });
        },
    },
    getters: {
        getAllUISettings(state) {
            return state.data;
        },
        getUISetting: (state) => (type) => {
            if (typeof(state.data.find(element => element.type === type)) != 'undefined') {
                return state.data.find(element => element.type === type).value;
            } else {
                return null;
            }
        }
    }
}
/*

    moduleGeneral.js

    Used to keep track on global variables
    apartmentId
    roomId
    contextHelp


    NOTE: 15.2.2022 - Should be deprecated totally!
    !! apartmendId and roomId should be transitioned to moduleApartments/activeApartment and moduleRooms/activeRoom
*/


// General data
export default {
    namespaced: true,
    state() {
        return {
            apartmentId: null,
            roomId: null,
            contextHelp: null,
        }
    },
    mutations: {
        SET_APARTMENTID(state, id) {
            state.apartmentId = id;
        },
        SET_ROOMID(state, id) {
            state.roomId = id;
        },
        SET_CONTEXT_HELP(state, id) {
            state.contextHelp = id;
        }
    },
    actions: {
        setApartment({ commit }, id) {
            commit('SET_APARTMENTID', id);
        },
        setRoom({ commit }, id) {
            commit('SET_ROOMID', id);
        },
        setContextHelp({ commit }, id) {
            commit('SET_CONTEXT_HELP', id);
        }
    },
    getters: {
        getCurrentApartment(state) {
            return state.apartmentId;
        }
    }
}
<template>
  <div class="main-nav" v-if="!isApartmentsView() && isLoggedIn()">
    <MenuClockLocalTime />
    <router-link v-if="!isAparmentView()" to="/apartment"><div class="menu-icon menu-icon-house"></div><span>Apartment</span></router-link>
    <router-link v-if="isAparmentView()" to="/apartments" class="router-link-active"><div class="menu-icon menu-icon-house"></div><span>Apartments</span></router-link>
    <router-link to="/profiles"><div class="menu-icon menu-icon-profiles"></div><span>Profiles</span></router-link>
    <router-link to="/consumption"><div class="menu-icon menu-icon-consumption"></div><span>Consumption</span></router-link>
    <router-link to="/production-and-consumption"><div class="menu-icon menu-icon-production"></div><span>Production and Consumption</span></router-link>
    <router-link to="/energycost"><div class="menu-icon menu-icon-energycost"></div><span>Energy Cost</span></router-link>
    <router-link to="/switches"><div class="menu-icon menu-icon-switches"></div><span>Switches</span></router-link>
    <router-link to="/settings"><div class="menu-icon menu-icon-settings"></div><span>Settings</span></router-link>
    <router-link to="/help"><div class="menu-icon menu-icon-help"></div><span>Help</span></router-link>
    <router-link to="/logout" class="logout"><div class="menu-icon menu-icon-logout"></div><span>Logout</span></router-link>
  </div>

  <div class="main-nav" v-if="isApartmentsView() && isLoggedIn()">
    <MenuClockLocalTime />
    <router-link to="/apartments" ><div class="menu-icon menu-icon-house"></div><span>Apartments</span></router-link>
    <router-link to="/logout" class="logout"><div class="menu-icon menu-icon-logout"></div><span>Logout</span></router-link>
  </div>

  <div class="main-nav" v-if="isTerms() && isLoggedIn()">
    <MenuClockLocalTime />
    <router-link to="/logout" class="logout"><div class="menu-icon menu-icon-logout"></div><span>Logout</span></router-link>
  </div>

  <div class="close-menu" @click="toggleMenu"></div>
  <div class="main-nav-hamburger" @click="toggleMenu"><i class="pi pi-bars"></i></div>
</template>

<script>
/*

  Navigation.vue

  Navigation sidebar component

  Shows different icons based on
    isApartmentsView
    isLoggedIn
    isTerms
  methods.

*/
import {checkIfLoggedIn } from '@/main';
import MenuClockLocalTime from '@/components/MenuClockLocalTime.vue';

export default {
  name: 'Navigation',
  components: {
    MenuClockLocalTime
  },
  methods: {
    isLoggedIn() {
      return checkIfLoggedIn();
    },
    isAparmentView() {
      return this.$route.name === 'Apartment';
    },
    isApartmentsView() {
      return this.$route.name === 'Apartments';
    },
    isTerms() {
      var lsFrom = localStorage.getItem('From');
      if(lsFrom == 'Apartment' && this.$route.name === 'Terms') return true;
      return false;
    },
    toggleMenu() {
      if(document.body.classList.contains('_menu_open')) {
        document.body.classList.remove('_menu_open');
      } else {
        document.body.classList.add('_menu_open');
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.main-nav {
  display:flex;
  flex-direction: column;
  height:100%;
  width:$menu-width;
  position:fixed;
  top:0;left:0;
  background-color: $menu-bg;
  box-shadow: $menu-shadow;
  z-index:1000;
  //overflow-y:auto;
  font-size:0;

  a {
    width:100%;
    aspect-ratio: 1; // polyfilled
    border-bottom: $menu-border;
    position:relative;

    
    &::before {
      float: left;
      padding-top: 100%;
      content: '';
    }

    &::after {
      content: '';
      display:block;
      clear: both;
      position:absolute;
      top:0;left:0;bottom:0;right:0;
      background-color: $menu-bg;
      mask-position:center center;
      mask-repeat: no-repeat;
      mask-size:cover;
      mask-image: url('../assets/icons/owui2_icon_menu_selected.svg');
    }

    &:hover {
      background-color: $menu-fg;
      .menu-icon {
        background-color: $menu-bg;
      }
    }

    &.router-link-active {
      background-color: $menu-fg;

      .menu-icon {
        background-color: $menu-bg;
      }
    }

    &.logout {
      border-bottom: none;
      border-top:$menu-border;
      margin-top: auto;

      &::after {
        content:none;
      }
    }

    .menu-icon {
      width:100%;
      height:100%;

      background-color:$menu-fg;
      display:inline-block;
      mask-position:center center;
      mask-repeat: no-repeat;
      mask-size:70%;
    }

    .menu-icon-house {
      mask-image: url('../assets/icons/owui2_icon_menu_house.svg');
    }
    .menu-icon-profiles {
      mask-image: url('../assets/icons/owui2_icon_menu_profiles.svg');
    }
    .menu-icon-consumption {
      mask-image: url('../assets/icons/owui2_icon_menu_consumption.svg');
    }
    .menu-icon-production {
      mask-image: url('../assets/icons/owui2_icon_menu_production.svg');
    }
    .menu-icon-switches {
      mask-image: url('../assets/icons/owui2_icon_menu_switch.svg');
    }
    .menu-icon-settings {
      mask-image: url('../assets/icons/owui2_icon_menu_settings.svg');
    }
    .menu-icon-help {
      mask-image: url('../assets/icons/owui2_icon_menu_help.svg');
    }
    .menu-icon-logout {
      mask-image: url('../assets/icons/owui2_icon_menu_logout.svg');
    }
    .menu-icon-energycost {
      mask-image: url('../assets/icons/owui2_icon_menu_energycost.svg');
    }
    span {
      display:none;
    }
  }
}
</style>

/*

    moduleSwitches.js

    Retrieves switches from API /switch/ and serves them to UI when needed through getters

    Actions uset to set and save switch settings to backend

*/

import axios from 'axios';

// Switches data
export default {
    namespaced: true,
    state() {
        return {
            activeSwitch: null,
            data: []
        }
    },
    mutations: {
        SET_SWITCHES(state, data) {
            state.data = data;
        },
        SET_ACTIVESWITCH(state, switchid) {
            state.activeSwitch = parseInt(switchid);
        },
        UPDATE_ACTIVESWITCH_DATA(state, data) {
            var index = state.data.map(aSwitch => aSwitch.id).indexOf(state.activeSwitch);
            state.data[index] = ({...state.data[index], ...data });
        },
        UPDATE_SWITCH(state, data) {
            var index = state.data.map(aSwitch => aSwitch.id).indexOf(parseInt(data.id));
            state.data[index] = ({...state.data[index], ...data.payload });
        }
    },
    actions: {
        getSwitches({ commit }, apartmentID) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_OW_API_URL + 'switch/?apartment=' + apartmentID + '&orderable=true')
                    .then(response => {
                        commit('SET_SWITCHES', response.data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        setActiveSwitch({ commit }, id) {
            commit('SET_ACTIVESWITCH', id);
            return { status: "ok", id: id };
        },
        patchSwitches({ commit }, data) {
            return new Promise((resolve, reject) => {
                var patchRequests = [];

                data.forEach((element) => {
                    commit('UPDATE_SWITCH', { id: element.id, payload: element.payload });
                    patchRequests.push(axios.patch(process.env.VUE_APP_OW_API_URL + 'switch/' + element.id + '/', element.payload));
                });

                // Each switch requeres own patch request.
                axios.all(patchRequests)
                    .then(
                        axios.spread((...responses) => {
                            resolve(responses);
                        })
                    )
                    .catch(errors => {
                        // react on errors.
                        reject(errors);
                    });
            });
        }
    },
    getters: {
        getActiveSwitch(state) {
            //return state.activeSwitch;
            return state.data.find(aSwitch => aSwitch.id === state.activeSwitch);
        },
        getAllSwitches(state) {
            // Send only the switches where orderable is not set
            return (state.data.filter(
                object => typeof object.orderable === 'undefined'
            ));
        },
        getAllowedSwitches(state) {
            // Send all switches, including the orderable
            const switches = (state.data.filter(
                object => typeof object.orderable === 'undefined' && object.switchtype != 'movementsensor'
            ));

            const orderables = (state.data.filter(
                object => typeof object.orderable != 'undefined'
            ));

            if (typeof(orderables[0].orderable) != 'undefined') {
                orderables[0].orderable.forEach(element => {
                    switches.push(element);
                });
            }

            return switches;
        }
    }
}
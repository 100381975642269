/*

    moduleRootAPI.js

    Retrieves root variables from API /root/ and serves them to UI when needed through getters

    At the moment is used for
    - MQTT variables and topics
    - Checking the controllers is realtime access allowed

*/

// root api data
import axios from 'axios';

export default {
    namespaced: true,
    state() {
        return {
            activeApartment: null,
            data: []
        }
    },
    mutations: {
        SET_ROOT_DATA(state, data) {
            state.data = data;
        },
        SET_ACTIVEAPARTMENT(state, apartmentid) {
            state.activeApartment = apartmentid;
        },
    },
    actions: {
        getRoot({ commit }, apartmentID) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_OW_API_URL + 'root/' + apartmentID + '/')
                    .then(response => {
                        commit('SET_ROOT_DATA', response.data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
    },
    getters: {
        getMQTT(state) {
            return state.data.mqtt;
        },
        getTopics(state) {
            return state.data.mqtt_topics;
        },
        isRealTimeAllowed(state) {
            if (typeof(state.data.controllers.find(e => e.allow_realtime_data === true)) != 'undefined') {
                return true;
            }
            return false;
        },
        getControllers(state) {
            if (typeof(state.data.controllers) != 'undefined') {
                return state.data.controllers;
            }
        }
    }
}
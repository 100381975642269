/*

    moduleAlgorithm.js

    Retrieves room heating algorithm choices from API /algorithm/ and serves them to UI when needed through getters

*/
import axios from 'axios';

export default {
    namespaced: true,
    state() {
        // Init state data
        return {}
    },
    mutations: {
        SET_ALGORITHMS(state, data) {
            state.data = data;
        },
    },
    actions: {
        fetchRoomAlgorithms({ commit }, roomid) {
            return new Promise((resolve, reject) => {
                // Retrieve algorithms
                axios.get(process.env.VUE_APP_OW_API_URL + 'algorithm/?room=' + roomid + '/')
                    .then(response => {
                        // Set algorithms state
                        commit('SET_ALGORITHMS', response.data.results);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
    },
    getters: {
        roomAlgorithms(state) {
            // Return all state data
            return state.data;
        }
    }
}
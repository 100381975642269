/*

    moduleRooms.js

    Retrieves rooms from API /rooms/ and serves them to UI when needed through getters

    Update room/rooms data through actions

*/

import axios from 'axios';
import clone from 'just-clone';

// Rooms data
export default {
    namespaced: true,
    state() {
        return {
            cancelsource: null,
            activeRoom: null,
            loadedApartment: null,
            data: []
        }
    },
    mutations: {
        SET_ROOMS(state, data) {
            state.data = data;
        },
        SET_ACTIVEROOM(state, roomid) {
            state.activeRoom = parseInt(roomid);
        },
        UPDATE_ACTIVEROOM_DATA(state, data) {
            var index = state.data.map(room => room.id).indexOf(state.activeRoom);
            state.data[index] = ({...state.data[index], ...data });
        },
        UPDATE_ALL_ROOMS_DATA(state, data) {
            state.data.forEach((element, index) => {
                state.data[index] = ({...element, ...data });
            });
        }
    },
    actions: {
        getRooms({ commit, state }, apartmentID) {
            return new Promise((resolve, reject) => {
                if (this.state.cancelsource != null) {
                   // this.state.cancelsource.cancel(); // UN-637 Fix?
                }

                // Get new cancel source
                this.state.cancelsource = axios.CancelToken.source();

                axios.get(process.env.VUE_APP_OW_API_URL + 'room/?apartment=' + apartmentID + '&page_size=2000', { cancelToken: this.state.cancelsource.token })
                    .then(response => {
                        if (typeof(response) != 'undefined') {
                            this.state.cancelsource = null;
                            state.loadedApartment = apartmentID;

                            commit('SET_ROOMS', response.data.results);
                            resolve(response);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        reorderRooms({ commit }, data) {
            return new Promise((resolve, reject) => {
                var patchRequests = [];

                data.forEach((element, index) => {
                    element.uiorder = index;

                    patchRequests.push(axios.patch(process.env.VUE_APP_OW_API_URL + 'room/' + element.id + '/', { uiorder: index }));
                });

                axios.all(patchRequests)
                    .then(
                        axios.spread((...responses) => {
                            resolve(responses);
                            commit('SET_ROOMS', data);
                        })
                    )
                    .catch(errors => {
                        reject(errors);
                    });
            });
        },
        setActiveRoom({ commit }, roomid) {
            commit('SET_ACTIVEROOM', roomid);
        },
        updateActiveRoom({ commit, state }, data) { // This is called from moduleApartment when one apartment is changed to update to global data

            // Strip away heaters before sending, but update activeroom data with full data?

            return new Promise((resolve, reject) => {
                axios.patch(process.env.VUE_APP_OW_API_URL + 'room/' + state.activeRoom + '/', data)
                    .then(response => {
                        commit('UPDATE_ACTIVEROOM_DATA', data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            });

        },
        updateAllRooms({ commit, state }, data) {
            // Update data to all rooms
            return new Promise((resolve, reject) => {
                var patchRequests = [];

                state.data.forEach((element) => {

                    var processed_data = data;

                    // Special data processing cases
                    if ('alert_limits' in processed_data) {
                        // alert_limits found. Fetch previous and combine
                        var current_alert_limits = clone(element.alert_limits);

                        processed_data.alert_limits.forEach(element => {
                            var objIndex = current_alert_limits.findIndex(e => e.limittype == element.limittype && e.valuetype == element.valuetype);

                            if (objIndex < 0) {
                                // old not found, push
                                current_alert_limits.push(element);
                            } else {
                                current_alert_limits[objIndex].value = element.value;
                            }
                        });

                        processed_data.alert_limits = current_alert_limits;
                    }

                    // ------

                    patchRequests.push(axios.patch(process.env.VUE_APP_OW_API_URL + 'room/' + element.id + '/', processed_data));
                });

                axios.all(patchRequests)
                    .then(
                        axios.spread((...responses) => {
                            // use/access the results
                            resolve(responses);
                            commit('UPDATE_ALL_ROOMS_DATA', data);
                        })
                    )
                    .catch(errors => {
                        // react on errors.
                        reject(errors);
                    });
            });
        }
    },
    getters: {
        getAllRooms(state) {
            return state.data;
        },
        getActiveRoom(state) {
            return state.data.find(room => room.id === state.activeRoom);
        },
        getRoom: (state) => (roomid) => {
            return state.data.find(room => room.id === roomid);
        },
        getAllRoomIDs(state) {
            return state.data.map(function(room) { return room['id'] });
        },
        getAllRoomActiveprofiles(state) {
            return state.data.map(function(room) { return room['activeprofile'] });
        },
        getFirstRoom(state) {
            return state.data[0];
        },
        getLoadedApartment(state) {
            return state.loadedApartment;
        }
    }
}
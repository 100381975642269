import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import { canUserAccess } from '@/main'
import NotFoundComponent from '@/views/NotFound.vue'

/*

    router/index.js

    Contains vue-router routes and beforeEach function to check user access rights and set localstorage From & HelpFrom variables

*/

const routes = [{
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/apartments',
        name: 'Apartments',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "apartments" */ '../views/Apartments.vue')
    },
    {
        path: '/apartment',
        name: 'Apartment',
        component: () =>
            import ( /* webpackChunkName: "apartment" */ '../views/Apartment.vue')
    },
    {
        path: '/profiles',
        name: 'Profiles',
        component: () =>
            import ( /* webpackChunkName: "profiles" */ '../views/Profiles.vue')
    },
    {
        path: '/consumption',
        name: 'Consumption',
        component: () =>
            import ( /* webpackChunkName: "consumption" */ '../views/Consumption.vue')
    },
    {
        path: '/production-and-consumption',
        name: 'ProductionAndConsumption',
        component: () =>
            import ( /* webpackChunkName: "production-and-consumption" */ '../views/ProductionAndConsumption.vue')
    },
    {
        path: '/switches',
        name: 'Switches',
        component: () =>
            import ( /* webpackChunkName: "switches" */ '../views/Switches.vue')
    },
    {
        path: '/switches/switch-settings',
        name: 'SwitchSettings',
        component: () =>
            import ( /* webpackChunkName: "switch-settings" */ '../views/SwitchSettings.vue')
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () =>
            import ( /* webpackChunkName: "settings" */ '../views/Settings.vue')
    },
    {
        path: '/help',
        name: 'Help',
        component: () =>
            import ( /* webpackChunkName: "help" */ '../views/Help.vue'),
        //beforeEnter: [helpBeforeEnter]
    },
    {
        path: '/help/guided-setup',
        name: 'GuidedSetup',
        component: () =>
            import ( /* webpackChunkName: "guided-setup" */ '../views/GuidedSetup.vue'),
        //beforeEnter: [helpBeforeEnter]
    },
    {
        path: '/help/terms',
        name: 'Terms',
        component: () =>
            import ( /* webpackChunkName: "terms" */ '../views/Terms.vue'),
        //beforeEnter: [helpBeforeEnter]
    },
    {
        /*
        :room_id adds the room id number to url.
        In the future also apartment id should be used but this caused lots of unresolved errors.
        Vue-router might not be ready for multiple url variables yet
        */
        path: '/room/:room_id',
        name: 'Room',
        component: () =>
            import ( /* webpackChunkName: "room" */ '../views/Room.vue'),
    },
    {
        path: '/room/:room_id/temperature-settings',
        name: 'RoomTemperatureSettings',
        component: () =>
            import ( /* webpackChunkName: "temperature-settings" */ '../views/TemperatureSettings.vue')
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () =>
            import ( /* webpackChunkName: "logout" */ '../views/Logout.vue')
    },
    {
        path: '/password',
        name: 'ResetPassword',
        component: () =>
            import ( /* webpackChunkName: "password" */ '../views/ResetPassword.vue')
    },
    {
        path: '/requestpasswordreset',
        name: 'RequestPasswordReset',
        component: () =>
            import ( /* webpackChunkName: "requestpasswordreset" */ '../views/RequestPasswordReset.vue')
    },
    {
        path: '/energycost',
        name: 'EnergyCost',
        component: () =>
            import ( /* webpackChunkName: "energycost" */ '../views/EnergyCost.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFoundComponent
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to, from) => {
    document.body.classList.remove('login');
    document.body.classList.remove('_menu_open'); // Portrait menu close
    //console.log(to);
    document.body.dataset.currentview = to.name;

    if (to.fullPath !== '/' && to.fullPath !== '/logout' && to.path !== '/password' && to.fullPath !== '/requestpasswordreset') { //
        if (!canUserAccess(to)) { // Simple access rights check in main.js
            return '/';
        } else {
            if ((to.name == 'Help' && from.name == 'GuidedSetup') || (from.name == 'Help' && to.name == 'GuidedSetup') || (to.name == 'Help' && from.name == 'Terms') || (from.name == 'Help' && to.name == 'Terms')) {
                // do nothing
            } else {
                localStorage.setItem('HelpFrom', from.name);
            }

            if (to.name != from.name) localStorage.setItem('From', from.name);
            return true;
        }
    } else {
        return true;
    }
});

router.afterEach(() => {
    //console.log('afterEach');
    window.dispatchEvent(new Event('refreshHeaderHeight'));
})

export default router
<template>
    <div class="menu-clock">
        {{ time }}
    </div>
</template>

<script>
/*

    Shows the local time of the apartment

*/


import moment from 'moment-timezone';
import { mapGetters } from "vuex";

export default {
    name: 'menu-clock',
    data() {
        return {
            time: ''
        }
    },
    mounted() {
        this.setTime();
        this.interval = setInterval(this.setTime, 60000);
    },
    computed: {
        ...mapGetters({getApartmentTZ: "apartments/getApartmentTZ"})
    },
    methods: {
        setTime() {
            var timez = this.getApartmentTZ;
            this.time = moment().tz(timez).format('HH:mm');
        }
    },
    beforeUnmount() {
        clearInterval(this.interval);
    }
}
</script>

<style lang="scss" scoped>
    .menu-clock {
        background-color: $menu-bg;
        color: $menu-clock;
        text-align:center;
        font-size:0.625rem;
        line-height: 1.75rem;
        border-bottom: $menu-border;
    }
</style>
/*

    moduleMeasurementdata.js

    Retrieves measurementdata from API /measurementdata/ and serves them to UI when needed through getters
    Used to get the latests measurements of an apartment

    Forecast.vue, RoomsList.vue

*/

import axios from 'axios';

// measurementdata data
export default {
    namespaced: true,
    state() {
        return {}
    },
    mutations: {
        SET_MEASUREMENTDATA(state, data) {
            state.data = data
        }
    },
    actions: {
        getMeasurementdata({ commit }, apartmentID) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_OW_API_URL + 'measurementdata/?apartment=' + apartmentID + '&page_size=2000')
                    .then(response => {
                        commit('SET_MEASUREMENTDATA', response.data.results);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
    },
    getters: {
        getHumidityMeasurements(state) {
            if (typeof(state.data) != 'undefined') {
                return state.data.filter(element => element.sensortypename === 'humidity');
            }
        },
        getTemperatureMeasurements(state) {
            if (typeof(state.data) != 'undefined') {
                return state.data.filter(element => element.sensortypename === 'temperature');
            }

        },
        getCO2Measurements(state) {
            if (typeof(state.data) != 'undefined') {
                return state.data.filter(element => element.sensortypename === 'co2');
            }
        }
    }
}
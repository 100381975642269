<template>
  <Navigation /> <!-- Navigation component -->
  <Motd /> <!-- Message of the day component -->
  <router-view /> <!-- Vue router vue -->
  <Toast/> <!-- Error toasts servive component -->
  <ConfirmDialog :closable="false"></ConfirmDialog> <!-- Confirm dialog component -->
  <div class="versionnumber">{{ getVersion }}</div>
  <div class="newversionavailable" id="newavailable">{{ $t('new-version')}} <a href="javascript:window.location.reload();">{{ $t('update')}}</a></div>
</template>

<script>
/*

  App.vue

  Main template of the whole UI.
  Holds main components.

  Router view handles all the "subviews"

*/
import { primelocales } from '@/assets/_ow_primevue_locales.js';
import { owlog } from '@/assets/owLib';
import Motd from '@/components/MotdBar.vue';
import Navigation from '@/components/Navigation.vue';
import { clearToken } from '@/main';
import axios from 'axios';
import moment from 'moment';
import { useToast } from "primevue/usetoast";

var axiostoast;
var myvue;

/*

  Global HTTP error handler

  TODO: Implement global localized responses from API

*/
function processError(error) {
  console.log('processError');
  console.log(error.response);

  /*
  var error_fields = "";
  if(Object.keys(error.response.data.fields).length) {

    error_fields = error.response.data.fields;
  }
  */

  axiostoast.add({ severity: 'error', summary: error.response.data.detail, detail: error.response.data.uuid }); // , detail: error_fields
}


axios.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    }, error => {

        if(!axios.isCancel(error)) {
          console.log(error);
          if (error.response.status) {
              axiostoast.removeAllGroups();
              myvue.$currentUser.reasonForLogout = '';

              switch (error.response.status) {
                  //case 400: // Bad request
                      // Handled in form components
                      //break;
                  case 401: // Unauthorized
                      // Sends user back to login with "expired token" message
                      myvue.$currentUser.reasonForLogout = myvue.$t('ow-error-token-expired');
                      clearToken();
                      break;
                  case 403: // Forbidden
                      // Sends user back to login with "access forbidden" message
                      myvue.$currentUser.reasonForLogout = myvue.$t('ow-error-access-forbidden');
                      clearToken();
                      break;
                  //case 404: // Not found
                      // This is forwarded by the vue-router
                      //break;
                  case 429: // Too many requests
                      processError(error);
                      break;
                  case 500: // Internal server error
                      processError(error);
                      break;
                  case 502: // Bad gateway
                      processError(error);
                      /*
                      setTimeout(() => {
                        router.replace({
                          path: "/login",
                          query: {
                            redirect: router.currentRoute.fullPath
                          }
                        });
                      }, 1000);
                      */
                    break;
                  default:
                    owlog(error.response.status);
                    //processError(error);
              }
              return Promise.reject(error.response);
          }
        }


    }
);

export default {
  data() {
    return {
      langcodes: {'en':'en-US', 'fi':'fi-FI', 'sv':'sv-SE'},
      reloading: false
    };
  },
  components: {
    Navigation,
    Motd
  },
  computed: {
    getVersion: function() { // Get the ui version from environmental variables. Version is fetched from the package.json to environment variable by vue.config.js script
      if(typeof(process.env.PACKAGE_VERSION) != 'undefined') {
        return process.env.PACKAGE_VERSION;
      } else {
        return '';
      }
    }
  },
  mounted() {
    //console.log('app mounted');
    myvue = this; // Setup myvue so we can use i18n in global error messaging
    axiostoast = useToast(); // Setup axiostoast so we can use toasts in global error messaging

    if (localStorage.getItem('owui2-language') != null && typeof(localStorage.getItem('owui2-language')) != 'undefined') {
        this.$root.$i18n.locale = localStorage.getItem('owui2-language');
    }
    this.changeLanguage(this.$root.$i18n.locale);


    // Version check every hour

    this.checkVersion();
    var that = this;

    var interval = 3599; // Default from environment is 3600. Just to see if env works
    if(typeof(process.env.VUE_APP_OW_PWA_VERSION_CHECK_INTERVAL) != 'undefined') interval = process.env.VUE_APP_OW_PWA_VERSION_CHECK_INTERVAL;

    //console.log(process.env.VUE_APP_OW_PWA_VERSION_CHECK_INTERVAL);
    //console.log(interval);

    setInterval(function() {
      that.checkVersion();
    }, interval * 1000);

  },
  methods: {
    changeLanguage(newLocale) {
      moment.locale(newLocale);
      //axios.defaults.headers.common['Accept-Language'] = this.langcodes[newLocale];
      axios.defaults.headers.common['Accept-Language'] = newLocale; // Backend uses short language codes
      this.$root.$primevue.config.locale = primelocales[newLocale];
    },
    checkVersion() {
      // Check version
      document.getElementById('newavailable').classList.remove('active');
      //console.log('check version');
      //console.log(process.env.PACKAGE_VERSION);
      if(typeof(process.env.PACKAGE_VERSION) != 'undefined') {
        // Don't check if we don't have package version
        // this will check the public/version file contents


        axios.get('/version?' + Math.floor(Math.random() * 1000000000000 )).then( response => { // cache buster for version file
          if(response.status == 200) {
            //console.log('response.data');
            //console.log(response.data);

            if(response.data != process.env.PACKAGE_VERSION) {
              //if(this.reloading) return false;
              //this.reloading = true;

              //console.log(process.env.PACKAGE_VERSION);
              //console.log(response.data);
              //console.log('should refresh!');

              document.getElementById('newavailable').classList.add('active');
            }
          }
        }).catch((error) => {
          console.log(error);
        });

      }
    }
  }
}
</script>

<style lang="scss">
  #app {
    width:100%;
    height:100%;
    position:relative;
  }

  .versionnumber {
    font-size:0.75rem;
    color: rgba(255,255,255,0.5);
    position:absolute;
    bottom:0.25rem;
    right:0.25rem;
    text-align:right;
  }

  .newversionavailable {
    display:none;
    position:absolute;
    top:0;
    left:0;
    right:0;
    line-height:2rem;
    text-align:center;
    color: $motd-fg;
    background-color: $motd-bg;
    z-index:99999;

    a {
      color: $motd-fg;
    }

    &.active {
      display:block;
    }
  }
</style>
